import find from "lodash/find";

export default {
    computed: {
        /**
         * Current user's cart status.
         */
        cartStatus() {
            return this.getCart(this.cartId)
        },

        /**
         * Whether the current user's cart has been assembled.
         */
        isAssembled() {
            return this.cartStatus.IsAssembled
        },

        /**
         * Whether the current user's cart has been shipped.
         */
        isShipped() {
            return this.cartStatus.IsShipped
        },

        hasDescription() {
            return this.purchase.DescriptionHtml.trim() !== ''
        },

        showAddButton() {
            return !this.purchase.IsLocked && (this.purchase.IsOpen || this.purchase.IsManaged)
        },
    },

    methods: {
        getCart(id) {
            const cart = find(this.purchase.Participants, { 'Id': id })

            return cart || {}
        },

        toggleAssembled(id) {
            const prev = this.getCart(id).IsAssembled
            this.getCart(id).IsAssembled = !prev
        },

        toggleShipped(id) {
            const prev = this.getCart(id).IsShipped
            this.getCart(id).IsShipped = !prev
        }
    }
}
