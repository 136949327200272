<template>
  <div class="bg-light p-3" style="margin-left:-15px;margin-right:-15px;" v-if="purchase.IsManaged || hasDescription">
    <template v-if="purchase.IsManaged">
      <div class="d-flex align-items-baseline justify-content-between">
        <h3>
          Участники <a :href="`/orders/${purchase.Id}/orderInvoice`" class="badge badge-success" style="font-size:50%;"
                       v-if="purchase.HasInvoices">счёт</a>
        </h3>
        <div v-if="purchase.HasInvoices">
          <label style="font-size:1rem;">
            <input type="checkbox" :checked="showParticipantInvoice" @click="toggleParticipantInvoices">
            счета
          </label>
        </div>
      </div>
      <PurchaseParticipantList :purchase="purchase" :cart-id="cartId"/>
    </template>
    <template v-if="purchase.IsManaged">
      <hr>
    </template>
    <template v-if="hasDescription">
      <h3>
        Описание закупки
        <small class="description-editor-button" title="Редактировать описание"
               @click="$refs.editorDialog.open()" v-if="purchase.IsManaged">
          <fa icon="edit"/>
        </small>
      </h3>
      <div class="purchase-description" v-html="purchase.DescriptionHtml"></div>
    </template>
    <template v-else-if="purchase.IsManaged">
      <button class="btn btn-primary" @click="$refs.editorDialog.open()">Добавить описание</button>
    </template>

    <DescriptionEditor ref="editorDialog" :id="purchase.Id" :description="purchase.Description" @updated="$emit('updated-description')" v-if="purchase.IsManaged"/>
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex'
  const PurchaseParticipantList = () => import(/* webpackChunkName: "advanced" */ '@/components/ParticipantList/PurchaseParticipantList')
  const DescriptionEditor = () => import(/* webpackChunkName: "advanced" */ '@/components/Purchase/DescriptionEditor')

  export default {
    name: "PurchaseSidebar",

    components: {PurchaseParticipantList, DescriptionEditor},

    props: ['purchase', 'cartId',],

    computed: {
      hasDescription() {
        return this.purchase.DescriptionHtml.trim() !== ''
      },

      ...mapGetters({
        showParticipantInvoice: 'participantList/showParticipantInvoice'
      }),
    },

    methods: {
      ...mapMutations({
        toggleParticipantInvoices: 'participantList/toggle',
      }),
    }
  }
</script>

<style scoped>
  .description-editor-button {
    cursor: pointer;
  }
</style>
