<template>
  <CenterScreen :logo="true">
    <div class="row">
      <div class="col-md-4 offset-md-4 col-12">
        <form method="post" @submit.prevent="submit" autocomplete="off">
          <div class="form-group">
            <label for="displayname">Имя и фамилия</label>
            <input class="form-control" id="displayname" placeholder="Ваше имя" required v-model="displayName">
          </div>
          <div class="form-group">
            <label for="mobilephone">Номер телефона{{'\xa0'}}/ Viber{{'\xa0'}}/ Telegram{{'\xa0'}}/ WhatsApp</label>
            <input class="form-control" id="mobilephone" placeholder="Ваш номер телефона" required
                   v-model="mobilePhone">
          </div>
          <div class="form-group">
            <label for="email">Электронная почта</label>
            <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
                   placeholder="Введите адрес электронной почты" name="username" required v-model="email">
          </div>
          <div class="form-group">
            <label for="password">Пароль</label>
            <input type="password" class="form-control" id="password" name="password" placeholder="Пароль" required
                   v-model="password">
          </div>
          <button type="submit" class="btn btn-primary mr-2">
            Зарегистрироваться
            <span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" v-if="loading"/>
          </button>
          <router-link :to="{name: 'login'}" class="btn">Войти</router-link>
          <div class="form-text text-muted"><small>* Все поля обязательны для заполнения.</small></div>
        </form>

        <div class="border-top d-flex justify-content-between mt-4">
          <div>
            <small>&copy; 2018&ndash;{{new Date().getFullYear()}}</small>
          </div>
          <div>
            <a href="https://about.simatogether.by/"><small>О программе</small></a>
          </div>
        </div>
      </div>
    </div>
  </CenterScreen>
</template>

<script>
  import Api from '@/api'
  import CenterScreen from '@/components/CenterScreen'

  export default {
    name: "Register",
    components: {CenterScreen},
    data() {
      return {
        email: '',
        password: '',
        displayName: '',
        mobilePhone: '',

        loading: false,
      }
    },

    methods: {
      submit() {
        this.loading = true
        Api.post(`/Register`, {
          Email: this.email,
          Password: this.password,
          DisplayName: this.displayName,
          MobilePhone: this.mobilePhone,
        }).then(() => {
          return this.$store.dispatch('auth/login', [this.email, this.password])
        }).then(() => {
          this.loading = false

          let target = {name: 'purchases'}
          if(this.$router.currentRoute.query.redirect) {
            target = this.$router.currentRoute.query.redirect
          }
          this.$router.replace(target)
        }).catch(e => {
          this.loading = false
          alert(e.message)
        })
      }
    }
  }
</script>

<style>
  html.route-register, html.route-register body {
    height: 100%;
  }

  html.route-register body {
    display: flex;
    align-items: center;
  }
</style>
