import Vue from 'vue'
import App from '@/App.vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faBalanceScaleLeft,
  faBoxOpen,
  faCartArrowDown,
  faCartPlus,
  faCogs,
  faCopy,
  faDollarSign,
  faDollyFlatbed,
  faEdit,
  faEllipsisV,
  faFileExcel,
  faLock,
  faLockOpen,
  faMinus,
  faPlus,
  faSave,
  faShieldAlt,
  faShippingFast,
  faShoppingBasket,
  faSyncAlt,
  faTimes,
  faTrashAlt,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import store from '@/store'
import router from '@/router'
import PluralRules from '@/util/plural-rules'

import memoize from 'lodash/memoize'
import {isProduction} from '@/util/env'

library.add([faLock, faLockOpen, faSave, faPlus, faMinus, faShippingFast, faBoxOpen, faShoppingBasket, faCogs,
    faUserPlus, faCartArrowDown, faDollyFlatbed, faFileExcel, faCopy, faDollarSign, faShieldAlt, faTrashAlt,
    faBalanceScaleLeft, faEdit, faSyncAlt, faEllipsisV, faTimes, faCartPlus, faBoxOpen])
Vue.component('fa', FontAwesomeIcon)

Vue.config.productionTip = false

const dateFmt = new Intl.DateTimeFormat('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
})
const timeFmt = new Intl.DateTimeFormat('ru', {
    hour: '2-digit',
    minute: '2-digit',
})
Vue.filter('moment', function(v) {
    const d = new Date(v)

    return `${dateFmt.format(d)} в ${timeFmt.format(d)}`
})

const moneyFmt = memoize((currency) => new Intl.NumberFormat('ru', {style: 'currency', currency: currency, minimumFractionDigits: 2}))
const moneyFmtAsIs = memoize((currency) => new Intl.NumberFormat('ru', {style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 20}))
const moneyFmtNoCurrency = new Intl.NumberFormat('ru', {minimumFractionDigits: 2})
Vue.filter('money', function (v, currency, asIs = false) {
  let formatter = moneyFmtNoCurrency
  if(currency) {
    if(asIs) {
      formatter = moneyFmtAsIs(currency)
    } else {
      formatter = moneyFmt(currency)
    }
  }

  return formatter.format(v)
})

const pluralsRu = new PluralRules('ru')
Vue.filter('plural', (n, ...options) => {
    switch(pluralsRu.select(n)) {
        case 'one':
            return (options[0] || '').replace('%v', n)
        case 'few':
            return (options[1] || options[0] || '').replace('%v', n)
        case 'many':
            return (options[2] || options[1] || options[0] || '').replace('%v', n)
        case 'other':
            return (options[3] || options[2] || options[1] || options[0] || '').replace('%v', n)
    }

    return options[0] || ''
})

store.dispatch('auth/reauth').catch(e => {
  const code = e.code

  if (code === 'not-authenticated' || code === 'bad-token') {
    return Promise.resolve()
  }

  alert(`Ошибка при входе. ${e.message} Попробуйте обновить страницу.`)
}).then(() => {
  if (store.getters['auth/isLoggedIn']) {
    // eslint-disable-next-line no-undef
    // noinspection JSUnresolvedFunction
    gtag('set', {'user_id': store.getters['auth/userId']})
  }

  Vue.prototype.$isProduction = isProduction()

  new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
      this.$store.commit('assembly/initialize')
      this.$store.commit('participantList/initialize')
    },
  }).$mount('#app')
})
