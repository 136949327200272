<template>
  <div>
    <h1>Редактирование профиля</h1>
    <ProfileEditor :user-id="userId"/>
    <div class="row">
      <div class="col-12 col-sm-8 col-md-6 col-lg-4">
        <h4>Пароль</h4>
        <p>Если хотите сменить пароль, введите новый пароль и подтвердите изменение вводом старого пароля. Если вы
          забыли свой старый пароль, вы можете <a href="/reset">сбросить пароль</a> через почту.</p>

        <PasswordForm/>

        <template v-if="canCreatePurchases">
          <h4>Интеграция с Sima-Land.ru</h4>
          <p>
            Для того, чтобы переносить содержимое закупки в корзину на сайте sima-land.ru в одно касание,
            необходимо привязать учётную запись в интернет-магазине.
          </p>
          <ol class="pl-3">
            <li>Войдите в <a href="https://www.sima-land.ru/cabinet/profile/" target="_blank">личный кабинет</a> на sima-land.ru.</li>
            <li>Скопируйте содержимое <a href="https://www.sima-land.ru/api/v3/auth/" target="_blank">страницы по ссылке</a>.</li>
            <li>Вставьте содержимое в поле ниже и сохраните.</li>
          </ol>

          <SimaCredentialsForm/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import userMx from '@/util/mixin-user'
  import PasswordForm from '@/components/Profile/PasswordForm'
  import SimaCredentialsForm from '@/components/Profile/SimaCredentialsForm'
  import ProfileEditor from '@/components/Profile/ProfileEditor'

  export default {
    name: "MyProfile",
    components: {ProfileEditor, SimaCredentialsForm, PasswordForm},
    mixins: [userMx],
  }
</script>
