<template>
  <div>
    <div class="d-flex flex-wrap align-items-baseline mb-2">
      <h1 class="mb-0 mr-2">
        <router-link class="back-button" :to="{ name: 'purchase', params: { id: id }}">&larr;</router-link>
        {{' '}}
        Корзина
        <router-link :to="profileRoute">{{cartStatus.DisplayName}}</router-link>
        {{' '}}
      </h1>
      <template v-if="purchase.CartParticipant.PickupPointId > 0">
        <div>
          <div class="badge badge-pickup font-weight-normal">
            {{purchase.CartParticipant.PickupPoint.DisplayName}}
            <template v-if="purchase.CartParticipant.PickupPoint.Address">
              <span class="badge-pickup-address">({{purchase.CartParticipant.PickupPoint.Address}})</span>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">

        <div class="alert alert-success mb-3" v-if="isShipped">
          <template v-if="cartId === userId">
            Заказ уже собран и передан вам.
          </template>
          <template v-else>
            Заказ собран и передан участнику.
          </template>
        </div>
        <div class="alert alert-success mb-3" v-else-if="isAssembled">
          <template v-if="cartId === userId">
            Ваш заказ уже собран.
          </template>
          <template v-else>
            Заказ уже собран.
          </template>
        </div>

        <div v-if="error.cart">
          <div class="alert alert-danger mb-3">
            <template v-if="is404">
              Закупка не найдена.
            </template>
            <template v-else>
              {{error.cart.message || 'Ошибка при загрузке информации о закупке.'}}
            </template>
          </div>
        </div>

        <div class="d-flex align-items-center mb-3">
          <div>
            <button class="btn btn-md-sm btn-success mr-1"
                    @click="$refs.addItemDialog.open({target: addItemDialogTarget})"
                    v-if="!purchase.IsLocked && (purchase.IsOpen || purchase.IsManaged)">
              <span class="d-md-none"><fa icon="plus"/></span>
              <span class="d-none d-md-inline">Добавить товар</span>
            </button>

            <template v-if="purchase.IsManaged || purchase.IsTransparentAssembly">
              <button class="btn btn-md-sm btn-info" type="button" @click="toggleAssemblyMode">
                <span class="d-md-none"><fa icon="box-open"/></span>
                <span class="d-none d-md-inline">Режим сборки</span>
              </button>
              {{' '}}
            </template>

            <template v-if="purchase.IsManaged">
              <button class="btn btn-md-sm btn-info" @click="$refs.rsvDialog.open()">
                <span class="d-md-none"><fa icon="shield-alt"/></span>
                <span class="d-none d-md-inline">Брони</span>
              </button>
              {{' '}}
              <router-link tag="a" class="btn btn-md-sm btn-dark"
                           :to="{ name: 'purchase-settings', params: { id } }">
                <span class="d-md-none"><fa icon="cogs"/></span>
                <span class="d-none d-md-inline">Настройки</span>
              </router-link>
              {{' '}}

              <div class="input-group input-group-md-sm d-inline-flex w-auto align-middle">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox" :checked="isAssembled" @click="handleIsAssembled">
                  </div>
                </div>
                <button class="btn btn-md-sm btn-secondary" type="button" @click="handleIsAssembled">
                  <span class="d-md-none"><fa icon="box-open"/></span>
                  <span class="d-none d-md-inline">Собрано</span>
                </button>
              </div>
              {{' '}}

              <div class="input-group input-group-md-sm d-inline-flex w-auto align-middle">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input type="checkbox" :checked="isShipped" @click="handleIsShipped">
                  </div>
                </div>
                <button class="btn btn-md-sm btn-secondary" type="button" @click="handleIsShipped">
                  <span class="d-md-none"><fa icon="shipping-fast"/></span>
                  <span class="d-none d-md-inline">Отдано</span>
                </button>
              </div>
              {{' '}}

              <button type="submit" class="btn btn-md-sm btn-outline-dark"
                      :title="purchase.IsLocked? 'Разблокировать закупку' : 'Заблокировать закупку'"
                      @click="toggleLock">
                <fa :icon="purchase.IsLocked? 'lock' : 'lock-open'"/>
              </button>
            </template>
          </div>

          <div v-if="loading.cart || loading.aux" class="ml-auto font-weight-normal text-right">
            <div class="spinner-border mr-2" style="width:1.7rem;height:1.7rem;" role="status"></div>
          </div>
        </div>

        <template v-if="!loaded.cart">
          <template v-if="loading.cart">
            <div class="spinner-border mr-2" role="status"></div>
            Загрузка...
          </template>
        </template>
        <template v-else>
          <p v-if="purchase.Items.length === 0">Корзина пуста.</p>
          <CartGridItem :item="item" :purchase="purchase"
                        :is-highlighted="articleNumber === item.ArticleId"
                        @add-item-clicked="$refs.addItemDialog.open({article: $event, target: addItemDialogTarget})"
                        @volume-changed="newVolume => handleVolumeChanged(item, newVolume)"
                        @assembly-changed="handleChangedAssembly"
                        @item-reservations="onItemReservations"
                        @edit-snapshot="articleId => $refs.snapshotEditor.open({articleId, volume: item.AggregateVolume})"
                        v-for="item in purchase.Items" :key="item.ArticleId"/>

          <table class="table">
            <tfoot>
            <tr v-if="!purchase.IsLocked && (purchase.IsOpen || purchase.IsManaged) && purchase.Items.length > 10">
              <td>
                <button type="button" class="btn btn-sm btn-success"
                        @click="$refs.addItemDialog.open({target: addItemDialogTarget})">Добавить товар
                </button>
              </td>
            </tr>
            <tr v-if="loaded.cart">
              <td class="text-right">
                <strong>Итого:</strong> {{purchase.Totals.Subtotal|money(purchase.CurrencyCode)}}
              </td>
            </tr>
            </tfoot>
          </table>
        </template>
      </div>
      <div class="col-12 col-md-4">
        <PurchaseSidebar :purchase="purchase" :cart-id="cartId" @updated-description="refreshCart(cartId)"/>
      </div>
    </div>

    <AddItemDialog ref="addItemDialog" :id="id" :participants="purchase.IsManaged? purchase.Participants : null"
                   @added-items="refreshCart(cartId)"/>
    <ReservationsListDialog ref="rsvDialog" :id="id" :cart-id="cartId" @deleted="refreshCart(cartId)"
                            @appended="refreshCart(cartId)"
                            @discrepancies="(id) => { $refs.rsvDialog.close(); $refs.rdd.open(id) }"
                            @show-report="(report) => { $refs.rsvDialog.close(); $refs.fillCartReport.open(report) }"
                            v-if="purchase.IsManaged"/>
    <ReservationDiscrepanciesDialog ref="rdd" @closed="$refs.rsvDialog.open()" v-if="purchase.IsManaged"/>
    <ItemReservationDialog ref="itemRsvDialog" :purchase="purchase" @updated="refreshCart(cartId)"
                           v-if="purchase.IsManaged"/>
    <FillCartReport ref="fillCartReport" @closed="$refs.rsvDialog.open()" v-if="purchase.IsManaged"/>
    <SnapshotEditor ref="snapshotEditor" :purchase="purchase" @updated="refreshCart(cartId)" v-if="purchase.IsManaged"/>
    <AddItemFloater v-if="showAddButton" @clicked="$refs.addItemDialog.open({target: addItemDialogTarget})"/>
  </div>
</template>

<script>
  import {purchase as initPurchase} from '@/util/defaults'
  import userMixin from '@/util/mixin-user'
  import purchaseMixin from '@/util/mixin-purchase'
  import Api from '@/api'
  import debounce from 'lodash/debounce'
  import AddItemDialog from '@/components/AddItemDialog'
  import {mapActions, mapMutations} from 'vuex'
  import PurchaseSidebar from '@/components/PurchaseSidebar'
  import AddItemFloater from '@/components/AddItemFloater'
  import CartGridItem from '@/components/Cart/CartGridItem'
  import {
    ACTION_PURCHASE_PARTICIPANT_ASSEMBLY_CHANGE,
    ACTION_PURCHASE_PARTICIPANT_SHIPPING_CHANGE,
    logFeatureUse
  } from '@/util/ga'

  const ReservationsListDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/ReservationsListDialog/ReservationsListDialog')
  const ReservationDiscrepanciesDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/ReservationDiscrepanciesDialog')
  const ItemReservationDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/ItemReservationDialog')
  const SnapshotEditor = () => import(/* webpackChunkName: "advanced" */ '@/components/SnapshotEditor')
  const FillCartReport = () => import(/* webpackChunkName: "advanced" */ '@/components/FillCartReport')

  export default {
    name: 'Cart',

    components: {
      FillCartReport, ReservationDiscrepanciesDialog, ReservationsListDialog, SnapshotEditor, AddItemFloater,
      PurchaseSidebar, CartGridItem, AddItemDialog, ItemReservationDialog
    },

    mixins: [userMixin, purchaseMixin],

    props: ['id', 'uid', 'articleNumber'],

    data() {
      return {
        loading: {
          cart: false,
          aux: false,
        },
        loaded: {
          cart: false,
          aux: false,
        },
        error: {
          cart: null,
          aux: null,
        },

        purchase: initPurchase,

        prevIsAssembled: false,
        prevIsShipped: false,
      }
    },

    computed: {
      cartId() {
        if (this.uid) {
          return this.uid
        }

        return this.userId
      },

      profileRoute() {
        const route = {}
        route.name = 'my-profile'
        if (this.cartId !== this.userId) {
          route.name = 'edit-profile'
          route.params = {id: this.cartId}
        }

        return route
      },

      is404() {
        if (!(this.error.cart instanceof Error)) {
          return false
        }

        return this.error.cart.response.status === 404
      },

      addItemDialogTarget() {
        if (this.cartId === this.userId) {
          return null
        }

        return this.cartId
      },
    },

    created() {
      this.fetchCart(this.cartId)
    },

    beforeRouteUpdate(to, from, next) {
      this.refreshCart(to.params.uid || this.userId)
      next()
    },

    methods: {
      ...mapMutations({
        toggleAssemblyMode: 'assembly/toggle',
      }),

      fetchCart(cid) {
        this.loaded.cart = false
        this.loading.cart = true
        this.error.cart = null
        Api.get(`/Purchases/${this.id}/Cart/${cid}`).then(r => {
          this.purchase = r.data
          this.loading.cart = false
          this.loaded.cart = true

          this.prevIsAssembled = this.purchase.IsAssembled
          this.prevIsShipped = this.purchase.IsShipped
        }).catch(e => {
          this.loading.cart = false
          this.error.cart = e
        })
      },

      refreshCart(cid) {
        if (this.cartId !== cid) {
          // Clear the table when switching carts.
          this.loaded.cart = false
        }
        this.loading.cart = true

        Api.get(`/Purchases/${this.id}/Cart/${cid}`).then(r => {
          this.purchase = r.data
          this.loading.cart = false
          this.loaded.cart = true
        }).catch(e => {
          this.loading.cart = false
          this.error.cart = e
        })
      },

      toggleLock() {
        Api.post(`/Purchases/${this.id}/ToggleLock`).then(() => {
          this.purchase.IsLocked = !this.purchase.IsLocked
        })
      },

      handleVolumeChanged(item, newVolume) {
        item.Volume = newVolume
        this.debouncedUpdateCartVolume(item)
      },

      debouncedUpdateCartVolume: debounce(function (item) {
        this.loading.aux = true
        Api.post(`/Purchases/${this.id}/Cart/${this.cartId}`, {
          ArticleId: item.ArticleId,
          Volume: item.Volume,
        }).then(() => {
          this.loading.aux = false
          this.refreshCart(this.cartId)
          this.lazyFetchParticipants({pid: this.id, articleNumber: item.ArticleId})
        }).catch(() => {
          this.loading.aux = false
          alert('Не удалось изменить количество товара.')
        })
      }, 300),

      handleChangedAssembly(item, status, note) {
        this.loading.aux = true
        Api.post(`/Assembly/${item.Id}`, {Status: status, Note: note}).then(() => {
          this.loading.aux = false
          item.AssemblyStatus = status
          item.AssemblyNote = note
        }).catch((e) => {
          this.loading.aux = false
          alert(`Ошибка. ${e.message}`)
        })
      },

      handleIsAssembled() {
        this.toggleAssembled(this.cartId)

        this.loading.aux = true
        Api.patch(`/Purchases/${this.id}/Cart/${this.cartId}`, {
          IsAssembled: this.isAssembled,
        }).then(() => {
          this.loading.aux = false
          logFeatureUse(ACTION_PURCHASE_PARTICIPANT_ASSEMBLY_CHANGE, 'Via toolbar')
        }).catch(e => {
          this.loading.aux = false
          alert(`Ошибка. ${e.message}`)

          this.toggleAssembled(this.cartId) // Undo the action.
        })
      },

      handleIsShipped() {
        this.toggleShipped(this.cartId)

        this.loading.aux = true
        Api.patch(`/Purchases/${this.id}/Cart/${this.cartId}`, {
          IsShipped: this.isShipped,
        }).then(() => {
          this.loading.aux = false
          logFeatureUse(ACTION_PURCHASE_PARTICIPANT_SHIPPING_CHANGE, 'Via toolbar')
        }).catch(e => {
          this.loading.aux = false
          alert(`Ошибка. ${e.message}`)

          this.toggleShipped(this.cartId) // Undo the action.
        })
      },

      onItemReservations(itemId) {
        this.$refs.itemRsvDialog.open(itemId)
      },

      ...mapActions({
        fetchParticipants: 'itemParticipants/fetchPurchaseItem',
        lazyFetchParticipants: 'itemParticipants/lazyFetchPurchaseItem',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";
  @import "~bootstrap/scss/mixins/_breakpoints.scss";

  .alert {
    margin-bottom: 0;
  }

  .alert + .alert {
    margin-top: 1rem;
  }

  .purchase-description img {
    max-width: 100%;
    height: auto;
  }

  @include media-breakpoint-between(xs, md) {
    .sima-purchase {
      width: auto;
      margin-left: -$grid-gutter-width/2;
      margin-right: -$grid-gutter-width/2;

      line-height: 1.25;

      td, th {
        padding: .5rem .5rem;
      }
    }
  }

  .badge-pickup {
    background: $bg-pickup;
    color: #fff;

    &-address {
      color: rgba(255, 255, 255, 0.8);
    }
  }
</style>
