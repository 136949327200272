export default {
  props: ['item', 'purchase'],

  computed: {
    price() {
      if (this.item.IsWholesale) {
        return this.item.WholesalePrice
      }

      return this.item.Price
    },

    itemName() {
      if (!this.item.IsAvailable) {
        return 'Товар не найден'
      }

      return this.item.Name
    },

    rowClass() {
      if (!this.item.IsAvailable || this.item.Balance === 0) {
        return 'item-unavailable'
      }

      if (this.item.IsFulfilled) {
        let multiplier = this.item.Multiplier
        if (multiplier === 0) {
          multiplier = 1
        }

        if (this.item.IsFulfilledEarly) {
          return 'item-fulfilled'
        }

        const unreserved = this.item.AggregateVolume - this.item.AggregateReservedVolume
        if (unreserved % multiplier > 0) {
          return 'item-multiplier-mismatch'
        }

        return 'item-fulfilled'
      }

      let reserved = this.item.ReservedVolume
      if (this.item.IsAggregate) {
        reserved = this.item.AggregateReservedVolume
      }

      // The item (in the cart) is fully reserved and is considered fulfilled.
      if (!this.item.IsFulfilled && reserved > 0 && this.item.Volume === 0) {
        return 'item-fulfilled'
      }

      if (!this.item.IsFulfilled && reserved > 0) {
        return 'item-unfulfilled-unreserved'
      }

      if (!this.item.IsFulfilled) {
        return 'item-unfulfilled'
      }

      return 'item-unavailable'
    }
  },
}
