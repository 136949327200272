<template>
  <CenterScreen>
    <div class="text-center">
      Страница не найдена. <a href="" @click.prevent="goBack">Вернуться</a>
    </div>
  </CenterScreen>
</template>

<script>
  import CenterScreen from '@/components/CenterScreen'

  export default {
    name: 'NotFound',
    components: {CenterScreen},
    methods: {
      goBack() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped>

</style>

<style>
  html.route-not-found, html.route-not-found body {
    height: 100%;
  }

  html.route-not-found body {
    display: flex;
    align-items: center;
  }
</style>
