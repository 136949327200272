<template>
  <div>
    <h1>{{purchase.Name}}</h1>
    <p :class="{'invisible': !purchase.Totals.ArticleCount}" v-if="purchase.IsManaged">
      Всего {{purchase.Totals.ArticleCount|plural('%v наименование', '%v наименования', '%v наименований')}},
      {{purchase.Totals.ItemCount|plural('%v единица', '%v единицы', '%v единиц')}}.
    </p>
    <div class="row">
      <div class="col-12 col-md-8">

        <div class="alert alert-success mb-3" v-if="isShipped">
          <template v-if="cartId === userId">
            Заказ уже собран и передан вам.
          </template>
          <template v-else>
            Заказ собран и передан участнику.
          </template>
        </div>
        <div class="alert alert-success mb-3" v-else-if="isAssembled">
          <template v-if="cartId === userId">
            Ваш заказ уже собран.
          </template>
          <template v-else>
            Заказ уже собран.
          </template>
        </div>

        <div v-if="isError">
          <template v-if="is404">
            <div class="alert alert-danger mb-3">Закупка не найдена.</div>
          </template>
          <template v-else>
            <div class="alert alert-danger mb-3" v-if="error.basic">
              {{error.basic.message || 'Ошибка при загрузке информации о закупке.'}}
            </div>
            <div class="alert alert-danger mb-3" v-else-if="error.delivery">
              Ошибка при загрузке информации о ценах на товары в закупке.
            </div>
          </template>
        </div>

        <div class="d-flex align-items-center mb-3">
          <div>
            <button class="btn btn-md-sm btn-success" @click="$refs.addItemDialog.open()" v-if="showAddButton">
              <span class="d-md-none"><fa icon="plus"/></span>
              <span class="d-none d-md-inline">Добавить товар</span>
            </button>
            {{' '}}
            <router-link tag="a" class="btn btn-md-sm btn-primary" :to="{ name: 'cart', params: { id } }">
              <span class="d-md-none"><fa icon="shopping-basket"/></span>
              <span class="d-none d-md-inline">Моя корзина</span>
            </router-link>
            {{' '}}

            <template v-if="purchase.IsManaged">
              <button class="btn btn-md-sm btn-info" @click="$refs.inviteDialog.open()" v-if="purchase.IsOpen">
                <span class="d-md-none"><fa icon="user-plus"/></span>
                <span class="d-none d-md-inline">Пригласить участника</span>
              </button>
              {{' '}}
              <button class="btn btn-md-sm btn-info" type="button" @click="toggleAssemblyMode">
                <span class="d-md-none"><fa icon="box-open"/></span>
                <span class="d-none d-md-inline">Режим сборки</span>
              </button>
              {{' '}}
              <button class="btn btn-md-sm btn-info" @click="$refs.rsvDialog.open()">
                <span class="d-md-none"><fa icon="shield-alt"/></span>
                <span class="d-none d-md-inline">Брони</span>
              </button>
              {{' '}}
              <router-link tag="a" class="btn btn-md-sm btn-dark"
                           :to="{ name: 'purchase-settings', params: { id } }">
                <span class="d-md-none"><fa icon="cogs"/></span>
                <span class="d-none d-md-inline">Настройки</span>
              </router-link>
              {{' '}}

              <button type="submit" class="btn btn-md-sm btn-outline-dark"
                      :title="purchase.IsLocked? 'Разблокировать закупку' : 'Заблокировать закупку'"
                      @click="toggleLock">
                <fa :icon="purchase.IsLocked? 'lock' : 'lock-open'"/>
              </button>
            </template>
          </div>

          <div v-if="isLoading" class="ml-auto font-weight-normal text-right">
            <div class="spinner-border mr-2" role="status" style="width:1rem;height:1rem;"></div>
          </div>
        </div>

        <template v-if="!isError && loaded.basic">
          <PurchaseGridItem :item="item" :purchase="purchase"
                    :purchase-loading="loading.basic" :purchase-loaded="loaded.basic"
                    @add-item-clicked="$refs.addItemDialog.open({article: $event})"
                    @assemble-item-clicked="$refs.assembleItemDialog.open({article: $event})"
                    @refreshed="refreshItems"
                    @item-reservations="onItemReservations"
                    @edit-snapshot="articleId => $refs.snapshotEditor.open({articleId, volume: item.Volume})"
                    v-for="item in purchase.Items" :key="item.ArticleId"/>

          <p v-if="purchase.Items.length === 0">
            Никто ещё не добавлял товары в закупку.
          </p>

          <div class="border-top pt-1 d-flex justify-content-between align-items-start">
            <div>
              <template v-if="showAddButton && purchase.Items.length > 0">
                <button type="button" class="btn btn-sm btn-success mt-2" @click="$refs.addItemDialog.open()">Добавить
                  товар
                </button>
              </template>
            </div>
            <table class="totals" v-if="loaded.delivery && purchase.IsManaged">
              <tr class="totals__row">
                <th>Итого:</th>
                <td class="totals__value">{{purchase.Totals.Subtotal|money('RUB')}}</td>
              </tr>
              <tr class="totals__row totals__row-muted" v-if="purchase.HasInvoices">
                <th>(по счёту):</th>
                <td class="totals__value">{{purchase.Totals.InvoiceTotal|money('RUB')}}</td>
              </tr>
              <tr class="totals__row">
                <th>Доставка:</th>
                <td class="totals__value">{{purchase.Totals.Delivery|money('RUB')}}</td>
              </tr>
              <tr class="totals__row">
                <th>Платная доставка:</th>
                <td class="totals__value">{{purchase.Totals.PaidDelivery|money('RUB')}}</td>
              </tr>
            </table>
          </div>
        </template>
      </div>
      <div class="col-12 col-md-4">
        <PurchaseSidebar :purchase="purchase" @updated-description="refreshMeta"/>
      </div>
    </div>

    <AddItemDialog ref="addItemDialog" :id="id" :participants="purchase.IsManaged? purchase.Participants : null"
                   @added-items="refreshItems"/>
    <AssembleItemDialog ref="assembleItemDialog" :id="id" @closed="refreshItems"/>
    <InviteDialog ref="inviteDialog" :id="id" :participants="purchase.IsManaged? purchase.Participants : null"
                  @invited="fetch" v-if="purchase.IsManaged"/>
    <ReservationsListDialog ref="rsvDialog" :id="id" @deleted="fetchRefresh"
                            @discrepancies="(id) => { $refs.rsvDialog.close();$refs.rdd.open(id) }"
                            @show-report="(report) => { $refs.rsvDialog.close();$refs.fillCartReport.open(report) }"
                            v-if="purchase.IsManaged"/>
    <ReservationDiscrepanciesDialog ref="rdd" @closed="$refs.rsvDialog.open()" v-if="purchase.IsManaged"/>
    <ItemReservationDialog ref="itemRsvDialog" :purchase="purchase" @updated="fetchRefresh" v-if="purchase.IsManaged"/>
    <FillCartReport ref="fillCartReport" @closed="$refs.rsvDialog.open()" v-if="purchase.IsManaged"/>
    <SnapshotEditor ref="snapshotEditor" :purchase="purchase" @updated="fetchRefresh" v-if="purchase.IsManaged" />

    <AddItemFloater v-if="showAddButton" @clicked="$refs.addItemDialog.open()"/>
  </div>
</template>

<script>
  import Api from '@/api'
  import userMixin from '@/util/mixin-user'
  import purchaseMixin from '@/util/mixin-purchase'
  import AddItemDialog from "@/components/AddItemDialog"
  import PurchaseSidebar from "@/components/PurchaseSidebar"
  import AddItemFloater from "@/components/AddItemFloater"
  import {purchase as initPurchase} from '@/util/defaults'
  import PurchaseGridItem from '@/components/Purchase/PurchaseGridItem'
  import {mapMutations} from "vuex";

  const InviteDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/InviteDialog')
  const ReservationsListDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/ReservationsListDialog/ReservationsListDialog')
  const ReservationDiscrepanciesDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/ReservationDiscrepanciesDialog')
  const ItemReservationDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/ItemReservationDialog')
  const FillCartReport = () => import(/* webpackChunkName: "advanced" */ '@/components/FillCartReport')
  const SnapshotEditor = () => import(/* webpackChunkName: "advanced" */ '@/components/SnapshotEditor')
  const AssembleItemDialog = () => import(/* webpackChunkName: "advanced" */ '@/components/Purchase/AssembleItemDialog')

  export default {
    name: "Purchase",
    components: {
      PurchaseGridItem, SnapshotEditor, FillCartReport, ItemReservationDialog, ReservationDiscrepanciesDialog,
      ReservationsListDialog, AddItemFloater, PurchaseSidebar, InviteDialog, AddItemDialog, AssembleItemDialog,
    },
    props: ['id'],

    mixins: [userMixin, purchaseMixin,],

    created() {
      this.fetch()
    },

    updated() {
      this.$root.$emit('triggerScroll')
    },

    data() {
      return {
        loading: {
          // Loading basic information.
          basic: true,

          // Loading financial data (item prices, shipping costs, discounts, etc.)
          delivery: true,
        },
        loaded: {
          basic: false, delivery: false,
        },
        error: {
          basic: null, delivery: null,
        },

        purchase: initPurchase,
      }
    },

    computed: {
      cartId() {
        return this.userId
      },

      isLoading() {
        return this.loading.basic || this.loading.delivery
      },

      isError() {
        return !!(this.error.basic || this.error.delivery)
      },

      is404() {
        if(!(this.error.basic instanceof Error)) {
          return false
        }

        return this.error.basic.response.status === 404
      },

      isOwned() {
        return this.purchase.OwnerId === this.userId
      },
    },

    methods: {
      ...mapMutations({
        toggleAssemblyMode: 'assembly/toggle',
      }),

      toggleLock() {
        Api.post(`/Purchases/${this.id}/ToggleLock`).then(() => {
          this.purchase.IsLocked = !this.purchase.IsLocked
        })
      },

      fetch() {
        let pDeliveries

        Api.get(`/Purchases/${this.id}`).then(r => {
          this.loading.basic = false
          this.loaded.basic = true
          this.purchase = Object.assign({}, r.data, {Items: Object.freeze(r.data.Items)})

          this.$root.$emit('triggerScroll')

          pDeliveries.then(r => {
            this.loading.delivery = false
            this.loaded.delivery = true
            this.purchase.Items = Object.freeze(r.data.Items)
            this.purchase.Totals = r.data.Totals
          })
        }).catch(e => {
          this.loading.basic = false
          this.error.basic = e
          // TODO: cancel loading other stuff if decide to go in parallel.
        })

        pDeliveries = Api.get(`/Purchases/${this.id}/DeliveryTotals`).catch((e) => {
          this.loading.delivery = false
          this.error.delivery = e
        })
      },

      fetchRefresh() {
        return Api.get(`/Purchases/${this.id}/DeliveryTotals`).then(r => {
          this.loading.delivery = false
          this.loaded.delivery = true
          this.purchase.Items = Object.freeze(r.data.Items)
          this.purchase.Totals = r.data.Totals
        }).catch((e) => {
          this.loading.delivery = false
          this.error.delivery = e
        })
      },

      refreshItems() {
        this.loading.delivery = true
        Api.get(`/Purchases/${this.id}/DeliveryTotals`).catch((e) => {
          this.error.delivery = e
        }).then(r => {
          this.loaded.delivery = true
          this.purchase.Items = Object.freeze(r.data.Items)
          this.purchase.Totals = r.data.Totals
        }).finally(() => {
          this.loading.delivery = false
        })
      },

      refreshMeta() {
        this.loading.delivery = true
        this.error.delivery = null
        Api.get(`/Purchases/${this.id}/Metadata`).then(r => {
          const p = r.data

          this.purchase.Description = p.Description
          this.purchase.DescriptionHtml = p.DescriptionHtml
          // TODO: Refresh more data.
        }).catch(e => {
          this.error.delivery = e
        }).finally(() => {
          this.loading.delivery = false
        })
      },

      onItemReservations(itemId) {
        this.$refs.itemRsvDialog.open(itemId)
      },
    }
  }
</script>

<style lang="scss" scoped>
    @import "../styles/variables";
    @import "~bootstrap/scss/mixins/_breakpoints.scss";

    .alert {
        margin-bottom: 0;
    }

    .alert + .alert {
        margin-top: 1rem;
    }

    .sima-purchase {
        margin-bottom: 0;
    }

    @include media-breakpoint-between(xs, md) {
        .sima-purchase {
            width: auto;
            margin-left: -$grid-gutter-width/2;
            margin-right: -$grid-gutter-width/2;

            line-height: 1.25;

            td, th {
                padding: .5rem .5rem;
            }
        }
    }

    .totals {
        th {
            text-align: right;
            padding-right: 1rem;
        }

        &__row {
            &-muted td, &-muted th {
                font-size: .875rem;
                line-height: 1;
                padding-bottom: .35rem;
                color: $gray-600;
            }
        }

        &__value {
            text-align: right;
        }
    }
</style>
