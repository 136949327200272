<template>
  <div class="container">
    <div class="row mb-4" v-if="logo">
      <div class="col text-center">
        <div class="logo display-3">
          SimaTogether
        </div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CenterScreen',
    props: {
      logo: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>

<style scoped>

</style>
