<template>
  <div class="multipliers text-muted d-flex justify-content-end small">
    <div class="pr-1 text-center" v-if="!equalPrices">
      <!-- price per piece column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`price-${ri.ArticleNumber}-${ri.ReservationId}`">
          {{ri.ClientPrice|money}}
        </div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`times-${item.ArticleId}-0`">{{item.ClientPrice|money}}</div>
      </template>
    </div>
    <div class="pr-1 text-center">
      <!-- x column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`times-${ri.ArticleNumber}-${ri.ReservationId}`">&times;</div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`times-${item.ArticleId}-0`">&times;</div>
      </template>
    </div>
    <div class="text-right">
      <!-- multiplier column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`multiplier-${ri.ArticleNumber}-${ri.ReservationId}`">
          {{ri.Volume}}
        </div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`multiplier-${item.ArticleId}-0`">{{item.Volume}}</div>
      </template>
    </div>
    <div class="px-1 text-center">
      <!-- = column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`equals-${ri.ArticleNumber}-${ri.ReservationId}`">=</div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`equals-${item.ArticleId}-0`">=</div>
      </template>
    </div>
    <div class="text-right">
      <!-- integer column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`integer-${ri.ArticleNumber}-${ri.ReservationId}`">
          {{formatInteger(ri.ClientTotal)}}
        </div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`integer-${item.ArticleId}-0`">{{unreservedInteger}}</div>
      </template>
    </div>
    <div class="text-center">
      <!-- , column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`point-${ri.ArticleNumber}-${ri.ReservationId}`">,</div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`point-${item.ArticleId}-0`">,</div>
      </template>
    </div>
    <div class="text-left">
      <!-- decimal column -->
      <template v-for="ri in reservedItems">
        <div class="font-weight-bold text-danger" :key="`decimal-${ri.ArticleNumber}-${ri.ReservationId}`">
          {{formatDecimal(ri.ClientTotal)}}
        </div>
      </template>
      <template v-if="showUnreserved">
        <div :key="`decimal-${item.ArticleId}-0`">{{unreservedDecimal}}</div>
      </template>
    </div>
  </div>
</template>

<script>
  import every from 'lodash/every'

  const intFmt = new Intl.NumberFormat('ru', {maximumFractionDigits: 0})

  export default {
    name: 'ItemMultiplierTable',
    props: ['item', 'reservedItems'],
    computed: {
      showUnreserved() {
        return (this.reservedItems.length > 0 && this.item.Volume > 0) || this.item.Volume > 1
      },

      unreservedInteger() {
        return this.formatInteger(this.item.ClientTotal)
      },

      unreservedDecimal() {
        return this.formatDecimal(this.item.ClientTotal)
      },

      equalPrices() {
        const pr = this.item.ClientPrice

        return every(this.reservedItems, ri => ri.ClientPrice === pr)
      }
    },
    methods: {
      formatInteger(priceStr) {
        if(!priceStr.includes('.')) {
          return priceStr
        }

        const parts = priceStr.split('.')

        return intFmt.format(parts[0])
      },

      formatDecimal(priceStr) {
        if(!priceStr.includes('.')) {
          return '00'
        }

        const parts = priceStr.split('.')

        return parts[1].padEnd(2, '0')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .multipliers {
    line-height: 1.25;
  }
</style>
