<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="el">
        <div class="modal-dialog" :class="[size? `modal-${size}`: '', scrollable? 'modal-dialog-scrollable' : '']" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title || 'Диалоговое окно'}}</h5>
                    <button type="button" class="close" data-dismiss="modal" title="Закрыть">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" :class="{'p-0': noPadding}">
                    <slot name="body"/>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import _ from "bootstrap/js/src/modal"
import {mapMutations} from "vuex";

const LEGAL_SIZES = ['sm', 'lg', 'xl',]

export default {
  name: "Modal",

  props: {
    title: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      validator(v) {
        return LEGAL_SIZES.indexOf(v) !== -1
      }
    },
    noPadding: {
      type: Boolean,
      required: false,
    },
    scrollable: {
      type: Boolean,
      required: false,
    }
  },

  mounted() {
    $(this.$refs.el).modal()
        .on('shown.bs.modal', () => this.$emit('opened', this.$el))
        .on('hidden.bs.modal', () => this.$emit('closed', this.$el))

    this.setActiveModal(true)
  },

  beforeDestroy() {
    // During navigation with the modal open, hide it explicitly to make the backdrop go.
    $(this.$refs.el).modal('hide')
    this.setActiveModal(false)
  },

  methods: {
    close() {
      $(this.$refs.el).modal('hide')
    },

    ...mapMutations({
      setActiveModal: 'setActiveModal',
    })
  }
}
</script>

<style scoped>

</style>
