<template>
  <form method="post" autocomplete="nope" @submit.prevent="submit">
    <div class="row form-group">
      <div class="col">
        <label for="simatoken">Содержимое страницы с токеном</label>
        <textarea class="form-control" id="simatoken" placeholder="&lt;response&gt;&lt;jwt&gt;eyJ...&lt;/jwt&gt;&lt;response&gt;" autocomplete="off" required v-model="token"></textarea>
      </div>
    </div>

    <div class="form-group">
      <Submitter :loading="loading" :error="error" :success="success"/>
    </div>
  </form>
</template>

<script>
  import Submitter from '@/components/Submitter'
  import Api from '@/api'
  import {ACTION_PROFILE_SIMA, logFeatureUse} from '@/util/ga'

  export default {
    name: 'SimaCredentialsForm',
    components: {Submitter},
    data() {
      return {
        login: '',
        password: '',
        token: '',

        loading: false,
        error: null,
        success: null,
      }
    },

    methods: {
      submit() {
        this.loading = true
        this.error = null
        this.success = null
        Api.post('/StoreSimaCredentials', {Token: this.token}).then(() => {
          this.loading = false
          this.success = 'Реквизиты доступа сохранены.'

          this.login = ''
          this.password = ''

          logFeatureUse(ACTION_PROFILE_SIMA)
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      }
    }
  }
</script>
