<template>
  <div>
    <template v-if="isWholesale">
      <span class="badge modifier modifier-wholesale ml-1">
        опт
      </span>
    </template>
    <template v-if="hasDiscount">
        <span class="badge modifier modifier-discount ml-1"
              title="На этот товар действует скидка. Цена за 1 шт указана с учётом скидки.">
          &minus;{{item.DiscountRate}}%
        </span>
    </template>
    <template v-if="isRemoteStore">
      <span class="badge modifier modifier-remote ml-1" title="Этот товар будет доставлен в более поздний срок.">
        партнёр
      </span>
    </template>
  </div>
</template>

<script>
  import includes from 'lodash/includes'

  export default {
    name: 'PriceModifiers',

    props: ['purchase', 'item'],

    computed: {
      isWholesale() {
        return this.item.IsWholesale
      },

      hasDiscount() {
        return this.item.DiscountRate > 0
      },

      isRemoteStore() {
        return this.item.IsRemoteStore
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .modifier {
    border: 1px solid;
    line-height: 1;
    font-size: 10px;
    cursor: default;
    margin-top: map-get($spacers, 1);
  }

  .modifier-wholesale {
    border-color: $success;
    color: $success;
  }

  .modifier-discount {
    border-color: #2962FF;
    color: #2962FF;
  }

  .modifier-remote {
    border-color: $danger;
    color: $danger;
  }
</style>
