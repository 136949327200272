import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Index from '@/views/Index'
import Login from '@/views/Login'
import App from '@/views/App'
import Purchases from '@/views/Purchases'
import Purchase from '@/views/Purchase'
import Cart from '@/views/Cart'
import Register from '@/views/Register'
import MyProfile from '@/views/MyProfile'
import Invite from '@/views/Invite'
import NotFound from '@/views/NotFound'
import {isProduction} from '@/util/env'

const PickupPoints = () => import(/* webpackChunkName: "advanced" */ '@/views/PickupPoints')
const NewPurchase = () => import(/* webpackChunkName: "advanced" */ '@/views/NewPurchase')
const ChangeProfile = () => import(/* webpackChunkName: "advanced" */ '@/views/ChangeProfile')
const PurchaseSettings = () => import(/* webpackChunkName: "advanced" */ '@/views/PurchaseSettings.vue')
const NewReservation = () => import(/* webpackChunkName: "advanced" */ '@/views/NewReservation.vue')
const ManageReservation = () => import(/* webpackChunkName: "advanced" */ '@/views/ManageReservation' )

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    beforeEnter: (to, from, next) => {
      if(store.getters['auth/isLoggedIn']) {
        next({name: 'purchases'})
        return
      }

      next({name: 'login'})
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {title: 'Вход',},
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {title: 'Регистрация',},
  },
  {
    path: '/invite/:id',
    name: 'invite',
    component: Invite,
    props: true,
    meta: {title: 'Приглашение в совместную закупку'},
  },
  {
    path: '/app',
    component: App,
    meta: {auth: true},
    children: [
      {
        path: '/purchases',
        name: 'purchases',
        component: Purchases,
        meta: {auth: true, title: 'Мои закупки',},
      },
      {
        path: '/purchases/new',
        name: 'new-purchase',
        component: NewPurchase,
        meta: {auth: true, title: 'Создание новой совместной закупки'},
      },
      {
        path: '/purchases/:id',
        name: 'purchase',
        component: Purchase,
        props: true,
        meta: {auth: true, title: 'Просмотр закупки', scrollByEvent: true,}
      },
      {
        path: '/purchases/:id/cart/:uid?',
        name: 'cart',
        component: Cart,
        props: true,
        meta: {auth: true, title: 'Просмотр корзины', scrollByEvent: true,}
      },
      {
        path: '/purchases/:id/settings',
        name: 'purchase-settings',
        component: PurchaseSettings,
        props: true,
        meta: {auth: true, title: 'Настройки закупки',}
      },
      {
        path: '/purchases/:id/reservations/new',
        name: 'new-reservation',
        component: NewReservation,
        props: true,
        meta: {auth: true, title: 'Новая бронь',},
      },
      {
        path: '/purchases/:id/reservations/:rid',
        name: 'manage-reservation',
        component: ManageReservation,
        props: true,
        meta: {auth: true, title: 'Управление бронью',},
      },
      {
        path: '/profile',
        name: 'my-profile',
        component: MyProfile,
        meta: {auth: true, title: 'Редактирование профиля',},
      },
      {
        path: '/profiles/:id',
        name: 'edit-profile',
        component: ChangeProfile,
        props: true,
        meta: {auth: true, title: 'Редактирование профиля'},
      },
      {
        path: '/pick-up',
        name: 'pick-up',
        component: PickupPoints,
        meta: {auth: true, title: 'Пункты выдачи'},
      },
    ]
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {title: 'Страница не найдена'},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let ret = {x: 0, y: 0}

    if(savedPosition) {
      ret = savedPosition
    } else if(to.hash) {
      ret = {selector: to.hash}
    }

    if(to.meta.scrollByEvent && (savedPosition || to.hash)) {
      return new Promise(resolve => {
        this.app.$root.$once('triggerScroll', () => {
          resolve(ret)
        })
      })
    }

    return ret
  }
})

router.beforeEach((to, from, next) => {
  if((to.name === 'login' || to.name === 'register') && store.getters['auth/isLoggedIn']) {
    if(to.query.redirect) {
      next(to.query.redirect)
      return
    }

    next({name: 'purchases'})
    return
  }

  if(to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if(!store.getters['auth/isLoggedIn']) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
      return
    }
  }

  next() // make sure to always call next()!
})

router.afterEach((to, from) => {
  if(from.name) {
    document.documentElement.classList.remove(`route-${from.name}`)
  }
  if(to.name) {
    document.documentElement.classList.add(`route-${to.name}`)
  }

  document.title = to.meta.title ? to.meta.title + ' | SimaTogether' : 'SimaTogether'

  if(isProduction() && store.getters['auth/isLoggedIn']) {
    // eslint-disable-next-line no-undef
    gtag('config', 'UA-9709111-5', {
      'page_title': to.meta.title,
      'page_path': to.path
    })
  }
})

export default router
