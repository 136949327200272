import {STATUS_NOT_ASSEMBLED} from '@/store/modules/assembly'

/**
 * Default view of the purchase.
 */
export const purchase = {
  Id: '',
  Name: 'Загрузка...',
  OwnerId: '',
  SourceId: 0,

  Description: '',
  DescriptionHtml: '',

  CurrencyCode: 'RUB',
  ExchangeRate: '1',

  IsManaged: false,
  IsLocked: false,
  IsOpen: false,
  IsPaidDelivery: false,
  IsTransparentVolume: false,
  IsTransparentAssembly: false,

  SettlementId: 0,

  IsHidden: false,

  HasInvoices: false,
  HasSnapshot: false,

  SnapshotId: '00000000-0000-0000-0000-000000000000',

  WholesaleCategories: [],
  Items: [],
  ReservedItems: {},

  Participants: [],
  CartParticipant: {
    // TODO: Specify all available fields.
    PickupPointId: 0,
    PickupPoint: {
      DisplayName: '',
      Address: '',
    }
  },

  Totals: {
    Subtotal: '0',
    Delivery: '0',
    PaidDelivery: '0',
    InvoiceTotal: '0',

    ArticleCount: 0,
    ItemCount: 0,
  },
}

// Changes to the structure must be also featured in simatogether/api.
export const item = {
  Id: '00000000-0000-0000-0000-000000000000',
  ArticleId: '0',
  Volume: 0,
  IsAggregate: false,
  AggregateVolume: 0,
  ReservedVolume: 0,
  AggregateReservedVolume: 0,
  HasSnapshot: false,
  IsAvailable: false,
  Price: '0.0',
  Balance: 0,
  MinimalVolume: 0,
  Multiplier: 0,
  Delivery: '0.0',
  IsPaidDelivery: false,
  IsRemoteStore: false,
  WholesaleCategory: 0,
  WholesalePrice: '0.0',
  DiscountRate: 0,
  IsFulfilled: false,
  IsFulfilledEarly: false,
  IsWholesale: false,
  ClientPrice: '0.0',
  ClientTotal: '0.0',
  AssemblyStatus: STATUS_NOT_ASSEMBLED,
  AssemblyNote: '',
  IsFullyAssembled: false,
  Name: '',
  ImageUrl: '',
}

// Changes to the structure must be also featured in simatogether/api/handleGetMyProfile().
export const profile = {
  Id: '',
  Email: '',
  DisplayName: '',
  MobilePhone: '',
  SettlementId: 0,

  Address1City: '',
  Address1Street: '',
  Address1BuildingNo: '',

  Address2City: '',
  Address2Street: '',
  Address2BuildingNo: '',
}

export const purchaseParticipant = {
  Id: '',
  OwnerId: '',
  DisplayName: '',
  Volume: 0,
  ReservationId: 0,
  IsReserved: false,
  AssemblyStatus: 0,
  AssemblyNote: '',
}
