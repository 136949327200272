<template>
    <div>
        Hello Moto!
    </div>
</template>

<script>
    export default {
        name: "Index",
    }
</script>

<style scoped>

</style>
