<template>
  <CenterScreen :logo="true">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-12">
        <p>Вас приглашают присоединиться к совместной закупке &laquo;{{invite.DisplayName}}&raquo;.</p>
        <template v-if="isLoggedIn">
          <template v-if="hasPickupPoints">
            <div class="mb-4">
              <label for="pickupPoints">Выберите пункт выдачи:</label>
              <select class="custom-select" id="pickupPoints" v-model="selectedPoint">
                <option :value="0" disabled>&ndash; Выберите пункт &ndash;</option>
                <option :value="point.Id" :key="point.Id" v-for="point in invite.PickupPoints">
                  {{point.DisplayName}}
                  <template v-if="point.Address">
                    ({{point.Address}})
                  </template>
                </option>
              </select>
            </div>

          </template>

          <div>
            <button class="btn btn-primary mr-2 mb-2" @click="accept">Принять приглашение</button>
            <router-link class="btn btn-link mb-2" :to="{name: 'purchases'}">Перейти к списку закупок</router-link>
          </div>

          <div class="spinner-border mr-2" role="status" v-if="loading"></div>
          <div class="text-danger" v-else-if="error">{{error}}</div>
        </template>
        <template v-else>
          <p>
            <router-link :to="{name: 'register', query: {redirect: $route.fullPath}}">Зарегистрируйтесь</router-link>
            или
            <router-link :to="{name: 'login', query: {redirect: $route.fullPath}}">войдите</router-link>,
            чтобы принять приглашение.
          </p>
        </template>
      </div>
    </div>
  </CenterScreen>
</template>

<script>
  import CenterScreen from '@/components/CenterScreen'
  import Api from '@/api'
  import find from 'lodash/find'

  export default {
    name: 'Invite',
    components: {CenterScreen},
    props: ['id'],
    data() {
      return {
        invite: {
          PurchaseId: '',
          DisplayName: '',
          PickupPoints: [],
        },
        selectedPoint: 0,

        loading: false,
        error: null,
      }
    },
    computed: {
      isLoggedIn() {
        return this.$store.getters["auth/isLoggedIn"]
      },
      hasPickupPoints() {
        return this.invite.PickupPoints.length > 0
      }
    },
    created() {
      if(!this.isLoggedIn) {
        return
      }

      this.fetchInvite()
    },
    methods: {
      accept() {
        if(this.hasPickupPoints && !this.selectedPoint) {
          alert('Необходимо выбрать пункт выдачи.')
          return
        }

        this.loading = true
        this.error = null
        Api.post('/AcceptInvite', {PurchaseId: this.id, PickupPointId: this.selectedPoint}).then(r => {
          const purchaseId = r.data.PurchaseId
          this.$router.push({name: 'purchase', params: {id: purchaseId}})
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      fetchInvite() {
        this.loading = true
        this.error = null
        Api.get(`/Invites/${this.id}`).then(r => {
          this.loading = false
          this.invite = r.data

          return this.invite
        }).then(invite => {
          const activePoint = find(invite.PickupPoints, {IsActive: true})

          if(activePoint) {
            this.selectedPoint = activePoint.Id
          }
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      }
    }
  }
</script>
