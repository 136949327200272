<template>
  <ul class="list-inline m-0">
    <li class="list-inline-item item-participant" v-for="pp in participants" :key="pp.Id">
      <router-link :to="{ name: 'cart', params: { id: purchaseId, uid: pp.OwnerId, articleNumber }, hash: `#${articleNumber}`, }">
        {{pp.DisplayName}}
      </router-link>
      {{' '}}
      <span class="badge badge-pill item-count" :class="[pp.IsReserved? 'badge-danger' : 'badge-info']"
            @click="$emit('item-reservations', pp.Id)">{{pp.Volume}}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ItemParticipantList',
  props: ['participants', 'purchaseId', 'articleNumber'],
}
</script>

<style lang="scss" scoped>
.item-count {
  cursor: pointer;

  font-weight: normal;
}

.item-participant {
  margin-right: 0;

  &::after {
    content: '\00B7';
    display: inline;
    padding: 0 .5rem;
  }

  &:last-child::after {
    display: none;
  }
}
</style>
