<template>
  <div class="submitter">
    <div :class="{'submitter__button-dirty': hasStatus}">
      <button type="submit" class="btn btn-primary" :class="btnClass" :disabled="loading" @click="$emit('submitted')">
        <slot>Сохранить</slot>
      </button>
    </div>
    <div :class="{'submitter__status-loading': loading}" v-if="hasStatus">
      <div class="spinner-border" role="status" v-if="loading"></div>
      <span class="text-danger" v-else-if="error">{{error}}</span>
      <span class="text-success" v-else-if="success">{{success}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Submitter',

  props: {
    loading: {
      type: Boolean,
    },
    error: {
      type: String,
    },
    success: {
      type: String,
    },
    btnClass: {
      required: false,
    }
  },

  computed: {
    hasStatus() {
      return !!(this.loading || this.error || this.success)
    }
  }
}
</script>

<style lang="scss">
  @import "src/styles/variables";

  .submitter {
    display: flex;
    align-items: center;

    &__button {
      &-dirty {
        padding-right: map-get($spacers, 2);
        margin-right: map-get($spacers, 2);
        border-right: $border-width solid $border-color;
      }
    }
  }

  .submitter__status-loading {
    line-height: 1;
  }
</style>
