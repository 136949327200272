<template>
  <form @submit.prevent="submit" autocomplete="nope">
    <div class="row">
      <div class="col-12 col-sm-8 col-md-6 col-lg-4">
        <div class="form-group">
          <label for="email">E-mail</label>
          <input type="email" class="form-control" id="email" readonly :value="profile.Email">
        </div>
        <div class="form-group">
          <label for="displayname">Имя</label>
          <input class="form-control" id="displayname" name="displayname" placeholder="Имя и фамилия" v-model="profile.DisplayName">
        </div>
        <div class="form-group">
          <label for="mobilephone">Мобильный телефон (Viber{{'\xa0'}}/ Telegram{{'\xa0'}}/ WhatsApp)</label>
          <input class="form-control" id="mobilephone" name="mobilephone"
                 placeholder="Мобильный телефон (Viber, Telegram)" v-model="profile.MobilePhone">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <h4>Адрес 1</h4>
        <div class="row">
          <div class="col-4 form-group">
            <label for="address1_buildingno">Дом</label>
            <input class="form-control" id="address1_buildingno" name="address1_buildingno" placeholder="Дом" v-model="profile.Address1BuildingNo">
          </div>
          <div class="col-8 form-group">
            <label for="address1_street">Улица</label>
            <input class="form-control" id="address1_street" name="address1_street" placeholder="Улица" v-model="profile.Address1Street">
          </div>
          <div class="col form-group">
            <label for="address1_city">Город</label>
            <input class="form-control" id="address1_city" name="address1_city" placeholder="Город" v-model="profile.Address1City">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <h4>Адрес 2</h4>
        <div class="row">
          <div class="col-4 form-group">
            <label for="address2_buildingno">Дом</label>
            <input class="form-control" id="address2_buildingno" name="address2_buildingno" placeholder="Дом" v-model="profile.Address2BuildingNo">
          </div>
          <div class="col-8 form-group">
            <label for="address2_street">Улица</label>
            <input class="form-control" id="address2_street" name="address2_street" placeholder="Улица" v-model="profile.Address2Street">
          </div>
          <div class="col form-group">
            <label for="address2_city">Город</label>
            <input class="form-control" id="address2_city" name="address2_city" placeholder="Город" v-model="profile.Address2City">
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="canCreatePurchases">
      <div class="col-12 col-sm-8 col-md-6 col-lg-4">
        <h4>Настройки закупок по умолчанию</h4>
        <p>Эти настройки будут применяться ко всем новым закупкам, которые вы создаёте.</p>

        <div class="form-group">
          <label for="email">Город доставки</label>
          <SimaSettlementSelector :id="profile.SettlementId" @updated="handleSettlement"/>
        </div>
      </div>
    </div>

    <div class="form-group">
      <Submitter :loading="loading" :error="error" @submitted="submit"/>
    </div>
  </form>
</template>

<script>
  import Api from '@/api'
  import {profile as profileDefault} from '@/util/defaults'
  import cloneDeep from 'lodash/cloneDeep'
  import Submitter from '@/components/Submitter'
  import {mapGetters} from 'vuex'
  import {ACTION_PROFILE_UPDATE, logFeatureUse} from '@/util/ga'
  const SimaSettlementSelector = () => import(/* webpackChunkName: "advanced" */ '@/components/SimaSettlementSelector/SimaSettlementSelector')

  export default {
    name: 'ProfileEditor',
    components: {SimaSettlementSelector, Submitter},
    props: ['userId'],

    data() {
      return {
        loading: false,
        error: null,

        profile: cloneDeep(profileDefault),
      }
    },

    computed: {
      ...mapGetters({
        canCreatePurchases: 'auth/canCreatePurchases',
      })
    },

    mounted() {
      this.fetch()
    },

    methods: {
      fetch() {
        Api.get(`/Profiles/${this.userId}`).then(r => {
          this.profile = r.data
        })
      },

      submit() {
        this.loading = true
        this.error = null
        Api.put(`/Profiles/${this.userId}`, this.profile).then(() => {
          this.loading = false

          logFeatureUse(ACTION_PROFILE_UPDATE)
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      handleSettlement(id) {
        if(!id) {
          return
        }

        this.profile.SettlementId = id
      }
    },
  }
</script>
