<template>
  <GridTable :class="[rowClass, {
    'show-meta': showEl.participants,
    'assembly-status__assembled': isFullyAssembled,
  }]">
    <template v-slot:image>
      <a :href="`https://www.sima-land.ru/${item.ArticleId}/`" target="_blank" class="thumb-link"
         @click="toggleParticipants">
        <img :src="item.ImageUrl" class="item-image" alt="">
      </a>
    </template>
    <template v-slot:description>
      <div>
        <a :href="`https://www.sima-land.ru/${item.ArticleId}/`" target="_blank">{{itemName}}</a>
      </div>
      <p class="text-muted mb-1 mb-md-0">
        <ItemStatus :purchase="purchase" :item="item" :fully-assembled="isFullyAssembled" @refreshed="$emit('refreshed')"
                    @edit-snapshot="articleId => $emit('edit-snapshot', articleId)"/>
      </p>

      <div class="d-md-none">
        <span class="mr-1" style="font-size: 120%; font-weight: 500;">{{item.ClientPrice|money}}</span>
        <span class="text-muted mr-1" style="font-size: 64%;">{{purchase.CurrencyCode}}</span>
        <PriceModifiers class="d-inline" :purchase="purchase" :item="item" />
      </div>
    </template>
    <template v-slot:meta v-if="showEl.participants">
      <div class="sima-item-participants mb-2">
        <ItemParticipantList :purchase-id="purchase.Id" :article-number="item.ArticleId"
                             :participants="participants"
                             @item-reservations="itemId => $emit('item-reservations', itemId)"/>
      </div>
    </template>
    <template v-slot:add-to-cart>
      <template v-if="purchase.IsManaged && assemblyMode">
        <button type="button" class="btn btn-assemble-item" title="Сборка товара"
                @click="$emit('assemble-item-clicked', item.ArticleId)">
          <fa icon="box-open" />
        </button>
      </template>
      <template v-else>
        <button type="button" class="btn btn-add-to-cart" title="Добавить в заказ"
                v-if="!purchase.IsLocked && (purchase.IsOpen || purchase.IsManaged)"
                @click="$emit('add-item-clicked', item.ArticleId)">
          <fa icon="shopping-basket"/>
        </button>
      </template>
    </template>
    <template v-slot:price>
      <div class="mb-1 mb-md-0">
        <span class="mr-1" style="font-size: 120%; font-weight: 500;">{{item.ClientPrice|money}}</span>
        <span class="text-muted mr-1" style="font-size: 64%;">{{purchase.CurrencyCode}}</span>
      </div>
      <PriceModifiers :purchase="purchase" :item="item" />
    </template>
    <template v-slot:volume>
      <div class="d-flex flex-column justify-content-between h-100">
        <div>
          <template
              v-if="purchase.IsTransparentVolume || purchase.IsManaged || !item.IsFulfilled || item.Multiplier > 1">
            <span class="px-1 text-nowrap"
                  :class="{ 'text-light': item.Multiplier > 1, 'bg-info': item.Multiplier > 1 }">
              {{item.Volume}} / {{item.IsFulfilled && item.Multiplier > 1 ? item.Multiplier : item.MinimalVolume}}
            </span>
            <div style="line-height: 1;" class="text-muted" v-if="item.Multiplier > 1">
              <small class="px-1">по {{item.Multiplier}}</small></div>
          </template>
          <template v-else-if="purchase.IsManaged && item.IsFulfilled">
            {{item.Volume}}
          </template>
          <div class="reserved-volume" v-if="purchase.IsManaged && item.ReservedVolume > 0">
            <span class="reserved-volume__volume">{{item.ReservedVolume}}</span>
          </div>
        </div>

        <div class="d-md-none">
          <template v-if="purchase.IsManaged && assemblyMode">
            <button type="button" class="btn btn-assemble-item" title="Сборка товара"
                    @click="$emit('assemble-item-clicked', item.ArticleId)">
              <fa icon="box-open" />
            </button>
          </template>
          <template v-else>
            <button type="button" class="btn btn-add-to-cart" title="Добавить в заказ"
                    v-if="!purchase.IsLocked && (purchase.IsOpen || purchase.IsManaged)"
                    @click="$emit('add-item-clicked', item.ArticleId)">
              <fa icon="shopping-basket"/>
            </button>
          </template>
        </div>
      </div>
    </template>
  </GridTable>
</template>

<script>
  import mixinItem from '@/util/mixin-item'
  import {mapActions, mapGetters} from 'vuex'
  import ItemStatus from '@/components/ItemStatus'
  import ItemParticipantList from '@/components/ItemParticipantList'
  import GridTable from '@/components/GridTable'
  import PriceModifiers from '@/components/Purchase/PriceModifiers'
  import {ACTION_PURCHASE_ITEM_PARTICIPANTS, logFeatureUse} from '@/util/ga'

  export default {
    name: 'PurchaseGridItem',
    components: {PriceModifiers, GridTable, ItemParticipantList, ItemStatus},
    mixins: [mixinItem,],

    data() {
      return {
        loading: {
          participants: false,
        },
        loaded: {
          participants: false,
        },
        error: {
          participants: null,
        },

        showEl: {
          participants: false,
        }
      }
    },

    computed: {
      participants() {
        return this.$store.getters['itemParticipants/participants'](this.purchase.Id, this.item.ArticleId)
      },

      isFullyAssembled() {
        if(!this.purchase.IsManaged || !this.assemblyMode) {
          return false
        }

        return this.item.IsFullyAssembled;
      },

      ...mapGetters({
        assemblyMode: 'assembly/isAssemblyMode',
      }),
    },

    methods: {
      toggleParticipants(e) {
        if (!this.purchase.IsManaged) {
          return
        }

        e.preventDefault()

        if (this.loading.participants) {
          return
        }

        if (this.loaded.participants) {
          this.showEl.participants = !this.showEl.participants

          return
        }

        if (this.showEl.participants) {
          this.showEl.participants = false

          return
        }

        this.loading.participants = true
        this.fetchArticleParticipants({pid: this.purchase.Id, articleNumber: this.item.ArticleId}).then(() => {
          this.loading.participants = false
          this.loaded.participants = true
          this.showEl.participants = true

          logFeatureUse(ACTION_PURCHASE_ITEM_PARTICIPANTS)
        }).catch(e => {
          this.error.participants = e
        })
      },

      ...mapActions({
        fetchArticleParticipants: 'itemParticipants/fetchPurchaseItem'
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";
  @import "src/styles/item-styles";

  .item-col {
    padding: $table-cell-padding 0;
  }

  .item-image {
    width: 100%;
    height: auto;
  }

  .item-price, .item-volume {
    justify-self: end;
    text-align: right;
  }

  .reserved-volume__volume {
    background: $danger;
    color: $gray-100;
    padding: 0 .25rem;
    font-size: 80%;

    cursor: pointer;
  }

  .btn-add-to-cart {
    color: $primary;
    font-size: 2.3rem;
    line-height: 1;
    padding: 0;
  }

  .btn-assemble-item {
    color: $info;
    font-size: 2.3rem;
    line-height: 1;
    padding: 0;
  }
</style>
