<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <router-link class="navbar-brand" to="/">SimaTogether</router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav mr-auto">
          <router-link tag="li" class="nav-item" to="/purchases" active-class="active">
            <a class="nav-link" href="/purchases">Мои закупки</a>
          </router-link>
          <router-link tag="li" class="nav-item" to="/pick-up" active-class="active" v-if="canCreatePurchases">
            <a class="nav-link" href="/pick-up">Пункты выдачи</a>
          </router-link>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="https://docs.simatogether.by/">Справка</a>
          </li>
          <router-link tag="li" class="nav-item" :to="{name: 'my-profile'}" active-class="active">
            <a class="nav-link" href="/profile">Профиль</a>
          </router-link>
          <li>
            <button type="button" class="btn nav-btn form-link-submit nav-item nav-link" @click="logout">Выход</button>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container-fluid">
      <router-view/>
    </div>
    <footer class="footer container-fluid">
      <div class="row">
        <div class="col">
          &copy; 2018&ndash;{{new Date().getFullYear()}} SimaTogether
          &middot;
          <a href="https://docs.simatogether.by/">Справка</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import 'bootstrap/js/src/collapse'
  import userMx from '@/util/mixin-user'
  import {ACTION_LOGOUT, logFeatureUse} from '@/util/ga'

  export default {
    name: "App",

    mixins: [userMx],

    methods: {
      logout() {
        logFeatureUse(ACTION_LOGOUT)

        this.$store.dispatch('auth/logout').finally(() => {
          this.$router.replace('/').catch(e => {
            if(!e) {
              return
            }

            console.log(e)
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .footer {
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    background: $gray-200;
  }

  .navbar {
    margin-bottom: 1rem;
  }
</style>
