import getPluralRules from 'intl-pluralrules/factory'
import { ru } from 'make-plural/plurals'
import { ru as ruCat } from 'make-plural/pluralCategories'

const sel = { ru }
const getSelector = lc => sel[lc]

const cat = { ru: ruCat, }
const getCategories = (lc, ord) => cat[lc][ord ? 'ordinal' : 'cardinal']

const PluralRules = getPluralRules(
    Intl.NumberFormat, // Not available in IE 10
    getSelector,
    getCategories
)

export default PluralRules
