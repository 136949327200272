import {ACTION_SIDEBAR_INVOICES_ENABLE, logFeatureUse} from "@/util/ga";

const LS_MODE_KEY = 'participant_list'

const ls = window.localStorage

const participantListModule = {
  namespaced: true,

  state: {
    showParticipantInvoice: false
  },

  getters: {
    showParticipantInvoice(state) {
      return state.showParticipantInvoice
    }
  },

  mutations: {
    initialize(state) {
      state.showParticipantInvoice = JSON.parse(ls.getItem(LS_MODE_KEY)) || false
    },

    toggle(state) {
      const newVal = !state.showParticipantInvoice
      state.showParticipantInvoice = newVal
      ls.setItem(LS_MODE_KEY, JSON.stringify(newVal))

      if (newVal) {
        logFeatureUse(ACTION_SIDEBAR_INVOICES_ENABLE)
      }
    }
  }
}

export default participantListModule
