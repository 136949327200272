import Vue from 'vue'
import Api from '../../api'
import has from 'lodash/has'

const itemParticipantsModule = {
  namespaced: true,

  state: {
    /**
     * Store keeps a nested structure: PurchaseId -> { ArticleNumber -> PurchaseParticipantItem }
     */
    store: {},
  },

  getters: {
    participants: (state) => (pid, articleNumber) => {
      if(!has(state.store, pid)) {
        return []
      }
      if(!has(state.store[pid], articleNumber)) {
        return []
      }

      return state.store[pid][articleNumber]
    }
  },

  mutations: {
    pushParticipants(state, {pid, articleNumber, participants}) {
      if(!has(state.store, pid)) {
        Vue.set(state.store, pid, {})
      }
      if(!has(state.store[pid], articleNumber)) {
        Vue.set(state.store[pid], articleNumber, [])
      }

      Vue.set(state.store[pid], articleNumber, participants)
    }
  },

  actions: {
    fetchPurchaseItem({commit}, {pid, articleNumber}) {
      return Api.get(`/Purchases/${pid}/ItemParticipants/${articleNumber}`).then(r => {
        commit('pushParticipants', {pid, articleNumber, participants: r.data})
      })
    },

    lazyFetchPurchaseItem({commit, state}, {pid, articleNumber}) {
      if(!has(state.store, pid) || !has(state.store[pid], articleNumber.toString())) {
        return new Promise(resolve => resolve())
      }

      return Api.get(`/Purchases/${pid}/ItemParticipants/${articleNumber}`).then(r => {
        commit('pushParticipants', {pid, articleNumber, participants: r.data})
      })
    }
  }
}

export default itemParticipantsModule
