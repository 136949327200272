import {ACTION_PURCHASE_ASSEMBLY_TOGGLE, logFeatureUse} from "@/util/ga";

const LS_MODE_KEY = 'assembly_mode'

const ls = window.localStorage

export const
  STATUS_NOT_ASSEMBLED = 0,
  STATUS_ASSEMBLY_NOTE = 1,
  STATUS_ASSEMBLED = 2

const assemblyModule = {
  namespaced: true,

  state: {
    isAssemblyMode: false
  },

  getters: {
    isAssemblyMode(state) {
      return state.isAssemblyMode
    }
  },

  mutations: {
    initialize(state) {
      state.isAssemblyMode = JSON.parse(ls.getItem(LS_MODE_KEY)) || false
    },

    toggle(state) {
      state.isAssemblyMode = !state.isAssemblyMode
      ls.setItem(LS_MODE_KEY, JSON.stringify(state.isAssemblyMode))

      logFeatureUse(ACTION_PURCHASE_ASSEMBLY_TOGGLE)
    }
  }
}

export default assemblyModule
