<template>
  <form method="post" autocomplete="nope" @submit.prevent="submit">
    <div class="row form-group">
      <div class="col">
        <label for="password">Новый пароль</label>
        <input class="form-control" style="display:none;"><!-- FF saved login anti-autocomplete hack -->
        <input type="password" class="form-control" id="password" placeholder="Новый пароль" required
               v-model="newPassword">
        <small class="form-text text-muted">Чтобы поменять пароль, необходимо заполнить оба поля.</small>
      </div>
      <div class="col">
        <label for="oldpassword">Старый пароль</label>
        <input type="password" class="form-control" id="oldpassword" placeholder="Старый пароль" required
               autocomplete="off" :class="{'is-invalid': badCurrentPassword}" v-model="currentPassword">
        <div class="invalid-feedback" v-if="badCurrentPassword">
          Неверный старый пароль. Если вы забыли свой пароль, вы можете его <a href="/reset">сбросить</a>.
        </div>
        <small class="form-text text-muted" v-else>Старый пароль необходим для подтверждения вашей личности.</small>
      </div>
    </div>

    <div class="form-group">
      <Submitter :loading="loading" :error="error" :success="success"/>
    </div>
  </form>
</template>

<script>
  import Submitter from '@/components/Submitter'
  import Api from '@/api'
  import {ACTION_PROFILE_PASSWORD, logFeatureUse} from '@/util/ga'

  export default {
    name: 'PasswordForm',
    components: {Submitter},
    data() {
      return {
        newPassword: '',
        currentPassword: '',

        loading: false,
        error: null,
        success: null,
        badCurrentPassword: false,
      }
    },

    methods: {
      submit() {
        this.loading = true
        this.error = null
        this.success = null
        this.badCurrentPassword = false

        Api.post('/ChangePassword', {NewPassword: this.newPassword, CurrentPassword: this.currentPassword}).then(() => {
          this.loading = false
          this.success = 'Пароль успешно обновлён.'

          this.newPassword = ''
          this.currentPassword = ''

          logFeatureUse(ACTION_PROFILE_PASSWORD)
        }).catch(e => {
          this.loading = false
          if(e.code === 'mismatch') {
            this.badCurrentPassword = true
          } else {
            this.error = e.message
          }
        })
      }
    }
  }
</script>
