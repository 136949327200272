<template>
  <form method="post" @submit.prevent="submit" autocomplete="off">
    <div class="form-group">
      <label for="email">Электронная почта</label>
      <input ref="login" type="email" class="form-control" id="email" aria-describedby="emailHelp" required
             placeholder="Введите адрес электронной почты" name="email" autofocus tabindex="5" v-model="email">
    </div>
    <div class="form-group">
      <label for="password">Пароль</label>
      <a href="/reset" class="reset-link" tabindex="40">Забыли пароль?</a>
      <input type="password" class="form-control" id="password" placeholder="Пароль" name="password" tabindex="10"
             required v-model="password">
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary mr-2" tabindex="20">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
        Войти
      </button>
      <router-link :to="{name: 'register'}" class="btn mr-2" tabindex="30">Зарегистрироваться</router-link>
    </div>
  </form>
</template>

<script>
  import {mapActions} from 'vuex'
  import {ACTION_LOGIN, logFeatureUse} from '@/util/ga'

  export default {
    name: 'LoginForm',
    data: () => ({
      email: '',
      password: '',

      loading: false,
    }),
    methods: {
      submit() {
        this.loading = true
        this.login([this.email, this.password]).then(() => {
          this.loading = false

          let target = {name: 'purchases'}
          if(this.$router.currentRoute.query.redirect) {
            target = this.$router.currentRoute.query.redirect
          }

          logFeatureUse(ACTION_LOGIN, 'Successful')

          this.$router.replace(target)
        }).catch(e => {
          this.loading = false

          const code = e.code
          if(code === 'no-user') {
            logFeatureUse(ACTION_LOGIN, 'Wrong credentials')

            alert(e.message)
            this.$refs['login'].focus()

            return
          }

          logFeatureUse(ACTION_LOGIN, 'Failed')

          alert('Ошибка при входе. Попробуйте снова. Если проблема не исчезнет, оповестите, пожалуйста, службу поддержки.')
        })
      },

      ...mapActions({
        login: 'auth/login'
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/variables";

  .reset-link {
    font-weight: $font-weight-light;
    margin-left: map-get($spacers, 2);
  }
</style>
