import Api, {DestroyToken, UseCredentials, UseRefresh} from '@/api'

const ROLE_ADMIN = 1
const ROLE_ORGANIZER = 2
const ROLE_PARTICIPANT = 4

const authModule = {
  namespaced: true,

  state: {
    user: null
  },

  getters: {
    /**
     * @param state
     * @returns {string|null}
     */
    userId(state) {
      if(!state.user) {
        return null
      }

      return state.user.Id
    },

    trackingId(state) {
      return state.user.TrackingId
    },

    isLoggedIn(state) {
      return !!state.user
    },

    isAdmin(state) {
      if(!state.user) {
        return false
      }

      return (state.user.Type & ROLE_ADMIN) !== 0
    },

    canCreatePurchases(state) {
      if(!state.user) {
        return false
      }

      const type = state.user.Type

      return (type & ROLE_ADMIN) !== 0 || (type & ROLE_ORGANIZER) !== 0
    }
  },

  mutations: {
    setUser(state, u) {
      state.user = u
    }
  },

  actions: {
    login({commit}, [username, password]) {
      return UseCredentials(username, password).then(() => {
        return Api.get('/WhoAmI').then(r => {
          commit('setUser', r.data)
        })
      }).catch(e => {
        commit('setUser', null)

        throw e
      })
    },

    /**
     * Logs out the user. The caller should assume that the user has been logged out unconditionally.
     *
     * @param commit
     * @returns {Promise<unknown>}
     */
    logout({commit}) {
      return Api.post('/Auth/Logout').catch(e => {
        console.log('Failed to log out')
      }).finally(() => {
        DestroyToken()
        commit('setUser', null)
      })
    },

    reauth({commit}) {
      return UseRefresh().then(() => {
        return Api.get('/WhoAmI')
      }).then(r => {
        commit('setUser', r.data)
      }).catch(e => {
        commit('setUser', null)

        return Promise.reject(e)
      })
    }
  }
}

export default authModule
