<template>
  <div class="gt" :class="{'gt-header': isHeader, 'gt-cart': isCart}">
    <div class="gt-col gt-image">
      <slot name="image"/>
    </div>
    <div class="gt-col gt-description">
      <slot name="description"/>
    </div>
    <div class="gt-col gt-meta">
      <slot name="meta"/>
    </div>
    <div class="gt-col gt-add-to-cart">
      <slot name="add-to-cart"/>
    </div>
    <div class="gt-col gt-price">
      <slot name="price"/>
    </div>
    <div class="gt-col gt-volume">
      <slot name="volume"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GridTable',

    props: {
      isHeader: Boolean,
      isCart: Boolean,
    },
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";
  @import "~bootstrap/scss/mixins/breakpoints";

  .gt {
    display: grid;
    grid-template-columns: minmax(4rem, 3fr) 5fr 2fr;
    grid-template-rows: repeat(2, auto);

    grid-template-areas:
      "image description volume"
      "meta meta meta";

    gap: $table-cell-padding;

    border-top: 1px solid $border-color;

    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    padding-top: $table-cell-padding;

    line-height: 1.21425;

    &-header {
      font-weight: bold;
      border-top-width: 2px;

      border-bottom: 1px solid $border-color;
    }
  }

  .gt-image {
    grid-area: image;
    padding-left: $table-cell-padding;
  }

  .gt-description {
    grid-area: description;
  }

  .gt-meta {
    grid-area: meta;
    padding-left: $table-cell-padding;
  }

  .gt-price {
    display: none;
    grid-area: price;
    padding-left: $table-cell-padding;
  }

  .gt-volume {
    grid-area: volume;
    padding-right: $table-cell-padding;
  }

  .gt-add-to-cart {
    display: none;
    grid-area: add-to-cart;
  }

  .gt-volume, .gt-price {
    justify-self: end;
    text-align: right;
  }

  .gt-add-to-cart {
    justify-self: center;
    align-self: center;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    .gt {
      margin-right: 0;

      grid-template-columns: minmax(8rem, 3fr) 20fr 1fr 4fr 3fr;
      grid-template-rows: repeat(2, auto);

      grid-template-areas:
      "image description add-to-cart price volume"
      "meta meta meta meta meta";

      line-height: 1.5;

      column-gap: 2*$table-cell-padding;
    }

    .gt-add-to-cart {
      display: block;
      justify-self: center;
      align-self: initial;
      text-align: center;
    }

    .gt-price {
      display: block;
    }

    .gt-meta {
      padding-right: $table-cell-padding;
    }
  }
</style>