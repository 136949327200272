<template>
    <div class="add-floater" :class="{'invisible': isActiveModal}">
        <div class="add-floater__btn" @click="$emit('clicked')"><fa icon="plus"/></div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'AddItemFloater',

        computed: {
            ...mapGetters(['isActiveModal'])
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/styles/variables";
    @import "~bootstrap/scss/mixins";

    .add-floater {
        position: fixed;
        width: 4rem;
        height: 4rem;
        bottom: 1rem;
        right: 1rem;
        z-index: 9999;

        @include media-breakpoint-up(md) {
            display: none;
        }

        &__btn {
            position: absolute;
            width: 100%;
            height: 100%;

            border-radius: 50%;
            font-size: 2.5rem;
            line-height: 4rem;
            text-align: center;

            background: $success;
            color: #fff;
            cursor: pointer;

            transition: $btn-transition;

            &:hover {
                background: darken($success, 7.5%)
            }
        }
    }
</style>
