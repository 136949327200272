<template>
  <CenterScreen :logo="true">
    <div class="row">
      <div class="col-md-4 offset-md-4 col-12">
        <LoginForm @submitted="onSubmitted"/>

        <div class="border-top d-flex justify-content-between">
          <div>
            <small>&copy; 2018&ndash;{{new Date().getFullYear()}}</small>
          </div>
          <div>
            <a href="https://docs.simatogether.by/"><small>Справка</small></a>
            &middot;
            <a href="https://about.simatogether.by/"><small>О программе</small></a>
          </div>
        </div>
      </div>
    </div>
  </CenterScreen>
</template>

<script>
  import LoginForm from '@/components/LoginForm'
  import CenterScreen from "@/components/CenterScreen";

  export default {
    name: "Login",
    components: {CenterScreen, LoginForm},
    data: () => ({
      submitting: false
    }),
    methods: {
      onSubmitted() {
        this.submitting = true
      }
    }
  }
</script>

<style>
  html.route-login, html.route-login body {
    height: 100%;
  }

  html.route-login body {
    display: flex;
    align-items: center;
  }
</style>
