import isObject from 'lodash/isObject'
import axios from 'axios'

let token = null;

function genBase() {
  const loc = window.location,
    hostname = loc.hostname + (loc.port? `:${loc.port}` : '')

  return `${loc.protocol}//${hostname}/api-n/v2`
}

const Api = axios.create({
  baseURL: genBase(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

export default Api

export function UseCredentials(username, password) {
  return Api.post('/Auth/Token', {Username: username, Password: password}).then(r => {
    token = Token.newFromResponse(r.data)

    return Promise.resolve()
  })
}

export function UseRefresh() {
  return Api.post('/Auth/Refresh').then(r => {
    token = Token.newFromResponse(r.data)
  })
}

export function DestroyToken() {
  token = null
}

Api.interceptors.request.use(config => {
  let sendToken = config.headers['X-NoAuth'] !== 'true'
  if(!sendToken) {
    return config
  }

  if(isObject(token) && token.isValid) {
    config.headers.Authorization = `Bearer ${token.token}`
  }

  return config
}, error => Promise.reject(error))

function createRefreshIntercept() {
  const interceptor = Api.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
      if(!error.response || error.response.status !== 401) {
        return Promise.reject(error);
      }

      const reqUrl = error.config.url.slice(error.config.baseURL.length)

      if(reqUrl === '/Auth/Token' || reqUrl === '/Auth/Refresh') {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      Api.interceptors.response.eject(interceptor);

      return Api.post('/Auth/Refresh').then(response => {
        token = Token.newFromResponse(response.data)
        error.response.config.headers['Authorization'] = `Bearer ${token.token}`;

        return Api(error.response.config);
      }).catch(error => {
        token = null

        return Promise.reject(error);
      }).finally(createRefreshIntercept); // Puts the interceptor back.
    }
  );
}

createRefreshIntercept()

Api.interceptors.response.use((resp) => resp, error => {
  return Promise.reject(new ApiError(error))
})

class ApiError {
  constructor(axiosError) {
    this.originalError = axiosError

    if(axiosError.response) {
      this.status = axiosError.response.status

      this.code = axiosError.response.status + ''
      if (axiosError.response.data && axiosError.response.data.Code) {
        this.code = axiosError.response.data.Code
      }

      this.message = `Произошла неизвестная ошибка (код ${this.code}). Попробуйте перезагрузить страницу.`
      if (axiosError.response.data && axiosError.response.data.Description) {
        this.message = axiosError.response.data.Description
      }
      // TODO: Add inner error.
    } else {
      this.message = axiosError.message
    }
  }
}

/**
 * Describes an API access token and its metadata.
 */
export class Token {
  // JWT string.
  #jwt;

  #issuedAt;

  /**
   * @param {Date}
   */
  #expiresAfter;

  get token() {
    return this.#jwt
  }

  get isValid() {
    let offsetNow = new Date()
    offsetNow.setMinutes(offsetNow.getMinutes() + 5)

    return this.#expiresAfter.getTime() > offsetNow.getTime()
  }

  static newFromResponse({AccessToken, IssuedAt, ExpiresAfter}) {
    let o = new Token()
    o.#jwt = AccessToken
    o.#issuedAt = new Date(IssuedAt)
    o.#expiresAfter = new Date(ExpiresAfter)

    return o
  }

}
