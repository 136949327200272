<template>
  <small>
    <span>
      Арт: {{item.ArticleId}}.
    </span>

    <template v-if="isCart || purchase.IsManaged">
      Цена 1&nbsp;шт:
      <template v-if="purchase.IsPaidDelivery || item.IsPaidDelivery">
                <span
                    class="text-nowrap">{{price|money}} <small>+{{item.Delivery|money('RUB')}}</small>.</span>
      </template>
      <template v-else>
        <span class="text-nowrap">{{price|money('RUB')}}.</span>
      </template>
    </template>

    <span class="text-uppercase ml-1" v-if="!item.Balance">Нет на складе.</span>

    <small class="text-warning ml-1" title="На этот товар действует платная доставка."
           v-if="item.IsPaidDelivery && !item.IsRemoteStore">Платная доставка.</small>

    <small class="text-nowrap text-info ml-1" v-if="fullyAssembled">Полностью собран.</small>

    <template v-if="purchase.IsManaged">
      <span class="snapshot snapshot-error ml-1"
            title="Цены в закупке зафиксированы, но фиксированных данных для этого товара нету. Необходимо зафиксировать цены вручную."
            @click="$emit('edit-snapshot', item.ArticleId)" v-if="purchase.HasSnapshot && !item.HasSnapshot">
        <fa icon="save"/>
      </span>
      <span class="snapshot ml-1" title="Редактировать информацию из заморозки"
            @click="$emit('edit-snapshot', item.ArticleId)" v-else-if="item.HasSnapshot">
        <fa icon="save"/>
      </span>

      <span class="refresh ml-1" title="Обновить информацию об артикуле." @click="refresh">
        <fa icon="sync-alt" :spin="loading"/>
      </span>
    </template>
  </small>
</template>

<script>
  import itemMx from '@/util/mixin-item'
  import Api from '@/api'

  export default {
    name: 'ItemStatus',
    mixins: [itemMx],
    props: ['purchase', 'item', 'isCart', 'fullyAssembled'],

    data() {
      return {
        loading: false,
      }
    },

    methods: {
      refresh() {
        if(this.loading) {
          return
        }

        this.loading = true
        Api.post('/RefreshArticle', {PurchaseId: this.purchase.Id, ArticleId: this.item.ArticleId}).then(() => {
          this.$emit('refreshed')
        }).catch(e => {
          alert(e.message)
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .refresh {
    color: $gray-500;
    cursor: pointer;

    &:hover {
      color: $gray-600;
    }
  }

  .snapshot {
    color: $gray-500;
    cursor: pointer;

    &:hover {
      color: $gray-600;
    }

    &-error {
      color: $danger;

      &:hover {
        color: darken($danger, 15%);
      }
    }
  }
</style>
