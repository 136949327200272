<template>
  <GridTable :is-cart="true" :class="[rowClass, assemblyStatusClass, {
    'show-meta': showEl.participants,
    'sima-item__focus': highlight
  }]">
    <template v-slot:image>
      <a :href="`https://www.sima-land.ru/${item.ArticleId}/`" target="_blank" class="thumb-link"
         @click="toggleParticipants">
        <img :src="item.ImageUrl" class="item-image" alt="">
      </a>
    </template>
    <template v-slot:description>
      <div>
        <a :href="`https://www.sima-land.ru/${item.ArticleId}/`" target="_blank">{{itemName}}</a>
      </div>
      <p class="text-muted mb-2 mb-md-0">
        <ItemStatus :purchase="purchase" :item="item" @refreshed="$emit('refreshed')" :is-cart="true"
                    @edit-snapshot="articleId => $emit('edit-snapshot', articleId)"/>
      </p>

      <div class="d-md-none">
        <span class="mr-1" style="font-size: 120%; font-weight: 500;">{{item.ClientPrice|money}}</span>
        <span class="text-muted mr-1" style="font-size: 64%;">{{purchase.CurrencyCode}}</span>
        <PriceModifiers class="d-inline" :purchase="purchase" :item="item" />
      </div>
    </template>
    <template v-slot:meta v-if="showEl.participants">
      <div class="sima-item-participants mb-2">
        <ItemParticipantList :purchase-id="purchase.Id" :article-number="item.ArticleId"
                             :participants="participants"
                             @item-reservations="itemId => $emit('item-reservations', itemId)"/>
      </div>
    </template>
    <template v-slot:add-to-cart>
      <div class="sima-item__assembly mt-2 mt-md-0 text-left text-md-right"
           v-if="(purchase.IsManaged || purchase.IsTransparentAssembly) && assemblyMode">
        <div v-if="purchase.IsManaged">
          <button class="btn sima-item__assembly-button pb-0" @click="toggleAssemblyStatus">
            <fa icon="cart-arrow-down" size="2x"/>
          </button>
        </div>
        <div class="sima-item__assembly-note text-left text-md-center"><small
            class="text-muted sima-item__assembly-note__text">{{assemblyStatusLabel}}</small></div>
      </div>
    </template>
    <template v-slot:price>
      <div class="mb-1 mb-md-0">
        <span class="mr-1" style="font-size: 120%; font-weight: 500;">{{item.ClientPrice|money}}</span>
        <span class="text-muted mr-1" style="font-size: 64%;">{{purchase.CurrencyCode}}</span>
      </div>
      <div class="d-none d-md-block mb-1" v-if="item.Volume > 1 || item.ReservedVolume > 0">
        <ItemMultiplierTable :item="item" :reserved-items="purchase.ReservedItems[item.ArticleId] || []"/>
      </div>
      <PriceModifiers :purchase="purchase" :item="item" />
    </template>
    <template v-slot:volume>
      <div class="d-flex flex-column justify-content-between h-100">
        <div>
          <div>
            <div class="volume" :class="{ 'table-danger': !purchase.IsOpen && itemVolumeForInput > 1 }">
              <template v-if="!purchase.IsLocked">
                <input class="item-volume-input" autocomplete="off" v-model.number.lazy="itemVolumeForInput">
              </template>
              <template v-else>
                {{itemVolume}}
              </template>
              <template v-if="!item.IsFulfilled"> / {{item.MinimalVolume}}</template>
            </div>
          </div>
          <div style="line-height: 1;" class="text-muted text-nowrap" v-if="item.Multiplier > 1">
            <small>по {{item.Multiplier}} шт</small>
          </div>

          <div class="mt-2 mb-2" v-if="!purchase.IsLocked && (purchase.IsManaged || purchase.IsOpen)">
            <div class="btn-group btn-group-sm">
              <button class="btn" type="button"
                      :class="{ 'btn-outline-danger': !decrementDisabled, 'btn-outline-secondary': decrementDisabled }"
                      :disabled="decrementDisabled" @click="itemVolume -= 1">
                <fa icon="minus"/>
              </button>
              <button class="btn btn-outline-success" type="button" @click="itemVolume += 1">
                <fa icon="plus"/>
              </button>
            </div>
          </div>

          <div class="mb-1 d-md-none" v-if="item.Volume > 1 || item.ReservedVolume > 0">
            <ItemMultiplierTable :item="item" :reserved-items="purchase.ReservedItems[item.ArticleId] || []"/>
          </div>
        </div>

        <div class="d-md-none align-self-end">
          <div class="sima-item__assembly mt-2 mt-md-0 text-left text-md-right"
               v-if="(purchase.IsManaged || purchase.IsTransparentAssembly) && assemblyMode">
            <div v-if="purchase.IsManaged">
              <button class="btn sima-item__assembly-button pb-0" @click="toggleAssemblyStatus">
                <fa icon="cart-arrow-down" size="2x"/>
              </button>
            </div>
            <div class="sima-item__assembly-note text-left text-md-center"><small
                class="text-muted sima-item__assembly-note__text">{{assemblyStatusLabel}}</small></div>
          </div>
        </div>
      </div>
    </template>
  </GridTable>
</template>

<script>
  import mixinItem from '@/util/mixin-item'
  import {mapActions, mapGetters} from 'vuex'
  import ItemStatus from '@/components/ItemStatus'
  import GridTable from '@/components/GridTable'
  import PriceModifiers from '@/components/Purchase/PriceModifiers'
  import ItemMultiplierTable from '@/components/ItemMultiplierTable'
  import {STATUS_ASSEMBLED, STATUS_ASSEMBLY_NOTE, STATUS_NOT_ASSEMBLED} from '@/store/modules/assembly'
  import has from 'lodash/has'
  import {ACTION_PURCHASE_CHANGE_VOLUME, ACTION_PURCHASE_ITEM_ASSEMBLY_CHANGE, logFeatureUse} from '@/util/ga'

  const ItemParticipantList = () => import(/* webpackChunkName: "advanced" */ '@/components/ItemParticipantList')

  const statusStates = {} // State table.
  statusStates[STATUS_NOT_ASSEMBLED] = STATUS_ASSEMBLED
  statusStates[STATUS_ASSEMBLED] = STATUS_ASSEMBLY_NOTE
  statusStates[STATUS_ASSEMBLY_NOTE] = STATUS_NOT_ASSEMBLED

  export default {
    name: 'CartGridItem',
    components: {GridTable, PriceModifiers, ItemStatus, ItemMultiplierTable, ItemParticipantList},
    mixins: [mixinItem],

    props: ['purchase', 'isHighlighted'],

    mounted() {
      const loc = window.location
      let viaHash = false

      this.highlight = this.isHighlighted
      if(loc.hash === `#${this.item.ArticleId}`) {
        viaHash = true
        this.highlight = true
      }

      if(this.highlight) {
        this.$el.scrollIntoView({'behavior': 'smooth', 'block': 'center'})

        if(viaHash) {
          window.history.replaceState('', document.title, loc.href.replace(loc.hash, ''))
        }
      }
    },

    data() {
      return {
        loading: {
          participants: false,
        },
        loaded: {
          participants: false,
        },
        error: {
          participants: null,
        },

        showEl: {
          participants: false,
        },

        highlight: false,
      }
    },

    computed: {
      itemVolume: {
        get() {
          return this.item.Volume + this.item.ReservedVolume
        },

        set(value) {
          logFeatureUse(ACTION_PURCHASE_CHANGE_VOLUME, 'Via volume buttons')

          this.$emit('volume-changed', value - this.item.ReservedVolume)
        },
      },

      itemVolumeForInput: {
        get() {
          return this.item.Volume + this.item.ReservedVolume
        },

        set(value) {
          logFeatureUse(ACTION_PURCHASE_CHANGE_VOLUME, 'Via volume input')

          this.$emit('volume-changed', value - this.item.ReservedVolume)
        },
      },

      decrementDisabled() {
        return this.item.Volume === 0
      },

      assemblyStatusClass() {
        if(!this.assemblyMode || (!this.purchase.IsManaged && !this.purchase.IsTransparentAssembly)) {
          return ''
        }

        switch(this.item.AssemblyStatus) {
          case STATUS_ASSEMBLED:
            return 'assembly-status__assembled'
          case STATUS_ASSEMBLY_NOTE:
            return 'assembly-status__note'
          default:
            return ''
        }
      },

      assemblyStatusLabel() {
        switch(this.item.AssemblyStatus) {
          case STATUS_ASSEMBLED:
            return 'Собрано.'
          case STATUS_ASSEMBLY_NOTE:
            return this.item.AssemblyNote
          default:
            return ''
        }
      },

      participants() {
        return this.$store.getters['itemParticipants/participants'](this.purchase.Id, this.item.ArticleId)
      },

      ...mapGetters({
        assemblyMode: 'assembly/isAssemblyMode',
      }),
    },

    methods: {
      toggleParticipants(e) {
        if(!this.purchase.IsManaged) {
          return
        }

        e.preventDefault()

        if(this.loading.participants) {
          return
        }

        if(this.loaded.participants) {
          this.showEl.participants = !this.showEl.participants

          return
        }

        if(this.showEl.participants) {
          this.showEl.participants = false

          return
        }

        this.loading.participants = true
        this.fetchArticleParticipants({pid: this.purchase.Id, articleNumber: this.item.ArticleId}).then(() => {
          this.loading.participants = false
          this.loaded.participants = true
          this.showEl.participants = true
        }).catch(e => {
          this.error.participants = e
        })
      },

      toggleAssemblyStatus() {
        let newState = this.item.AssemblyStatus,
            newNote = ''

        newState = statusStates[this.item.AssemblyStatus]
        if(!has(statusStates, newState)) {
          newState = STATUS_NOT_ASSEMBLED
        }

        if(newState === STATUS_ASSEMBLY_NOTE) {
          const noteReq = prompt('Введите пометку к товару. Чтобы снять все пометки, отмените.', '')
          if(noteReq === null) {
            newState = STATUS_NOT_ASSEMBLED
          } else {
            newNote = noteReq
          }
        }

        logFeatureUse(ACTION_PURCHASE_ITEM_ASSEMBLY_CHANGE)

        this.$emit('assembly-changed', this.item, newState, newNote)
      },

      ...mapActions({
        fetchArticleParticipants: 'itemParticipants/fetchPurchaseItem',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";
  @import "~bootstrap/scss/mixins/breakpoints";
  @import "src/styles/item-styles";

  .item-col {
    padding: $table-cell-padding 0;
  }

  .item-image {
    width: 100%;
    height: auto;
  }

  .item-price, .item-volume {
    justify-self: end;
    text-align: right;
  }

  .reserved-volume__volume {
    background: $danger;
    color: $gray-100;
    padding: 0 .25rem;
    font-size: 80%;

    cursor: pointer;
  }

  .btn-add-to-cart {
    color: $primary;
    font-size: 2.3rem;
    line-height: 1;
    padding: 0;
  }


  .sima-item__focus {
    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width $primary;
  }

  @include media-breakpoint-up(md) {
    .sima-item .thumb-column {
      width: 4.5rem;
    }
  }

  .item-volume-input {
    display: inline-block;
    width: 2rem;
    padding: 0;

    border: none;
    background: inherit;
    text-align: right;
  }

  .item-volume-input:focus {
    box-shadow: 0 0 .25rem .125rem #007bff;
    border-radius: .125rem;
  }
</style>
