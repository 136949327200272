import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userId: 'auth/userId',
      isAdmin: 'auth/isAdmin',
      canCreatePurchases: 'auth/canCreatePurchases',
    }),
  }
}
