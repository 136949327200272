<template>
  <Modal title="Добавить в корзину" ref="modal" @opened="handleOpened" @closed="close" v-if="show">
    <template v-slot:body>
      <form @submit.prevent="submitAddItem">
        <div class="form-group">
          <label>Ссылка или артикул товара</label>
          <input class="form-control" ref="articleNo" required autofocus v-model="article">
        </div>
        <div class="form-group">
          <label for="volume">Количество</label>
          <input type="number" class="form-control" id="volume" min="1" required value="1" v-model.number="volume">
        </div>
        <div class="form-group" v-if="participants">
          <label for="onbehalfof">Добавить в корзину:</label>
          <select id="onbehalfof" class="form-control" v-model="target">
            <option :value="null">Свою</option>
            <option :value="pp.Id" v-for="pp in participants" :key="pp.Id">{{pp.DisplayName}}</option>
          </select>
        </div>
        <button type="submit" style="display:none;">Добавить</button>
      </form>
    </template>
    <template v-slot:footer>
      <div class="spinner-border mr-2" role="status" v-if="loading"></div>
      <div class="text-danger" v-if="error">{{error}}</div>
      <button type="button" class="btn btn-primary" @click="submitAddItem">Добавить</button>
    </template>
  </Modal>
</template>

<script>
  import Api from "../api";
  import Modal from "./Modal";
  import mixinUser from '../util/mixin-user'
  import {ACTION_PURCHASE_ADD_ITEM, logFeatureUse} from '@/util/ga'

  export default {
    name: "AddItemDialog",

    components: {Modal},

    mixins: [mixinUser,],

    props: ['id', 'participants',],

    data() {
      return {
        article: '',
        volume: 1,
        target: null,

        show: false,

        loading: false,
        error: null,
      }
    },

    methods: {
      submitAddItem() {
        this.loading = true
        this.error = null
        Api.put(`/Purchases/${this.id}/Items`, {
          ArticleId: this.article + '',
          Volume: this.volume,
          Target: this.target || this.userId,
        }).then(() => {
          logFeatureUse(ACTION_PURCHASE_ADD_ITEM)

          this.$refs.modal.close()

          this.$emit('added-items')
        }).catch(e => {
          this.error = e.message
          if(e.code === 'failed-validation') {
            this.$refs['articleNo'].focus()
          }
        })
        .finally(() => {
          this.loading = false
        })
      },

      open({article, target} = {}) {
        this.article = article || ''
        this.volume = 1
        this.target = target || null
        this.show = true
      },

      handleOpened() {
        // Fix autofocus.
        this.$refs['articleNo'].focus()
      },

      close() {
        this.article = ''
        this.volume = 1
        this.target = null
        this.show = false
      }
    }
  }
</script>

<style scoped>

</style>
