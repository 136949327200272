<template>
  <div>
    <h1>Мои закупки</h1>

    <table class="table">
      <thead>
        <tr v-if="canCreatePurchases">
          <th colspan="3">
            <router-link class="btn btn-primary" :to="{name: 'new-purchase'}">Новая закупка</router-link>
          </th>
        </tr>
        <tr>
          <th>Название</th>
          <th>Организатор</th>
          <th>Дата создания</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr>
            <td colspan="3">
              <div class="spinner-border mr-2" role="status"></div>
              Загрузка...
            </td>
          </tr>
        </template>
        <template v-else-if="error">
          <tr>
            <td colspan="3">
              <div class="alert alert-error">
                Не удалось загрузись список закупок. {{error}}
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="p in purchases" :key="p.Id">
            <td>
              <router-link :to="{ name: 'purchase', params: { id: p.Id } }">{{p.Name}}</router-link>
              <span class="ml-2 badge badge-secondary" v-if="p.IsArchived">в архиве</span>
            </td>
            <td>{{p.OwnerName}} <em v-if="p.OwnerId === userId">(вы)</em></td>
            <td>{{p.CreatedAt | moment}}</td>
          </tr>
          <tr v-if="!purchases.length">
            <td colspan="3">
              Вы пока что не участвовали в совместных закупках.
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="canCreatePurchases">
        <tr>
          <td colspan="3">
            <router-link class="btn btn-primary" :to="{name: 'new-purchase'}">Новая закупка</router-link>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import userMx from '@/util/mixin-user'
  import Api from '../api'

  export default {
    name: "Purchases",

    mixins: [userMx],

    data() {
      return {
        purchases: [],
        loading: true,
        loaded: false,
        error: null
      }
    },

    created() {
      Api.get('/Purchases').then(r => {
        this.loading = false
        this.purchases = r.data.Items
      }).catch(e => {
        this.loading = false
        this.error = e.message
      })
    },

    computed: {
      ...mapGetters({
        canCreatePurchases: 'auth/canCreatePurchases',
      })
    },
  }
</script>
