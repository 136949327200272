import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import assembly from './modules/assembly'
import participantList from './modules/participant-list'
import itemParticipants from './modules/item-participants'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    isActiveModal: false,
  },
  getters: {
    isActiveModal(state) {
      return state.isActiveModal
    }
  },
  mutations: {
    setActiveModal(state, v) {
      state.isActiveModal = v
    }
  },
  actions: {
  },
  modules: {
    auth, assembly, participantList, itemParticipants
  }
})

export default store
