export const CATEGORY_FEATURE_USE = 'Feature Use'
export const CATEGORY_SCREEN_VIEW = 'Screen View'

export const ACTION_LOGIN = 'Log into system'
export const ACTION_LOGOUT = 'Log out of system'

export const ACTION_PICKUP_CREATE = 'Create pick-up point'
export const ACTION_PICKUP_DELETE = 'Delete pick-up point'

export const ACTION_PROFILE_UPDATE = 'Update profile'
export const ACTION_SETTLEMENT_EDITOR = 'Settlement editor'
export const ACTION_PROFILE_PASSWORD = 'Change password'
export const ACTION_PROFILE_SIMA = 'Change Sima-Land credentials'

export const ACTION_PURCHASE_CREATE = 'Create purchase'
export const ACTION_PURCHASE_HIDE = 'Hide purchase'
export const ACTION_PURCHASE_ADD_ITEM = 'Add item to purchase'
export const ACTION_PURCHASE_CHANGE_VOLUME = 'Change purchase item volume'
export const ACTION_PURCHASE_CHANGE_DESCRIPTION = 'Change purchase description'
export const ACTION_PURCHASE_ITEM_PARTICIPANTS = 'Reveal purchase item participants'
export const ACTION_PURCHASE_ASSEMBLY_TOGGLE = 'Purchase assembly mode toggle'
export const ACTION_PURCHASE_ITEM_ASSEMBLY_CHANGE = 'Purchase item assembly state changed'
export const ACTION_PURCHASE_PARTICIPANT_ASSEMBLY_CHANGE = 'Purchase participant assembly changed'
export const ACTION_PURCHASE_PARTICIPANT_SHIPPING_CHANGE = 'Purchase participant shipping changed'
export const ACTION_SIDEBAR_INVOICES_ENABLE = 'Enable sidebar invoice links'

export const ACTION_PURCHASE_ASSEMBLY_WINDOW = 'Use purchase item assembly overview window'
export const ACTION_PURCHASE_ASSEMBLY_WINDOW_TOGGLE_PARTICIPANT = 'Toggle one participant assembly in overview window'
export const ACTION_PURCHASE_ASSEMBLY_WINDOW_TOGGLE_ALL = 'Toggle all participants assembly in overview window'

export const ACTION_FILL_SIMA_CART = 'Send items to sima-land.ru'
export const ACTION_SNAPSHOT_CREATE = 'Create a snapshot'
export const ACTION_INVOICE_UPLOAD = 'Upload an invoice file'
export const ACTION_PARTICIPANTS_SUMMARY = 'Participants summary'

export const ACTION_RESERVATION_CREATE = 'Create reservation'
export const ACTION_RESERVATION_CHANGE = 'Change reservation'
export const ACTION_RESERVATION_DELETE = 'Delete reservation'
export const ACTION_RESERVATION_CHANGE_ITEM = 'Change item reservation'

export function logFeatureUse(action, label, value) {
  const params = {
    event_category: CATEGORY_FEATURE_USE,
  }
  if (label) {
    params.event_label = label
  }
  if (typeof value !== 'undefined') {
    params.value = value
  }

  gtag('event', action, params)
}

export function getReservationFeatureUseLabel(rsv) {
  let order = 'Without order'
  let snapshot = 'Without snapshot'

  if (rsv.SimaOrderId > 0) {
    order = 'With order'
  }
  if (rsv.SnapshotId !== '00000000-0000-0000-0000-000000000000') {
    snapshot = 'Snapshot attached'
  }

  let labelComponents = [order, snapshot]

  if (rsv.Items) {
    let items = 'All items'

    if (!rsv.Items.AllItems) {
      items = 'Select items'
    }

    labelComponents = [items, snapshot, order]
  }

  return labelComponents.join(', ')
}
